import { gqlMutationClient } from '~/lib/backend';

const uploadFileAction = async (
  fileExt: 'pdf' | 'png' | 'jpg',
  mimeType: 'image/png' | 'image/jpg' | 'application/pdf',
  body: BodyInit,
  contentLength?: string
) => {
  const fileUpload = await gqlMutationClient()({
    makeUpload: [
      {
        fileExt,
        mimeType
      },
      {
        __typename: true,
        '...on GraphQLError': { message: true },
        '...on MutationMakeUploadSuccess': {
          data: {
            fileId: true,
            uploadUrl: true,
            file: {
              id: true,
              url: true
            }
          }
        }
      }
    ]
  });

  if (
    !fileUpload.makeUpload ||
    fileUpload.makeUpload.__typename === 'GraphQLError'
  ) {
    throw new Error(fileUpload.makeUpload?.message ?? 'Unexpected error');
  }

  await fetch(fileUpload.makeUpload.data.uploadUrl ?? 'should-never-happen', {
    method: 'PUT',
    headers: {
      'Content-Type': mimeType,
      ...(contentLength
        ? {
            'Content-Length': contentLength
          }
        : {})
    },
    body
  });

  return fileUpload.makeUpload.data;
};

export default uploadFileAction;
