import { captureException } from '@sentry/react';
import { useForm } from '@tanstack/react-form';
import classNames from 'classnames';
import { useAtom } from 'jotai';
import { Fragment, useState } from 'react';
import PhoneInput from 'react-phone-number-input';
import { toast } from 'react-toastify';
import { cognitoRequestOtpAction } from '~/actions/auth/cognitoRequestOtpAction';
import { cognitoVerifyOtpAction } from '~/actions/auth/cognitoVerifyOtpAction';
import recordConsentAction from '~/actions/auth/recordConsentAction';
import requestOtpExistingUserDEVAction from '~/actions/auth/requestOtpExistingUserDEVAction';
import verifyOtpResponseAction from '~/actions/auth/verifyOtpResponseAction';
import TextInput from '~/components/inputs/TextInput';
import config from '~/config';
import { gqlMutationClient } from '~/lib/backend';
import { resetMeQuery } from '~/queries/meQuery';
import { useNavigate } from '~/router';
import Button from '../components/Button';
import Modal from '../components/Modal';
import { authStateAtom } from '../state/auth';

export default function SignIn() {
  const [, setAuthState] = useAtom(authStateAtom);
  // Modal states
  const [otpModalIsOpen, setOtpModalIsOpen] = useState(false);
  const [consentModalIsOpen, setConsentModalIsOpen] = useState(false);

  // This is the email we request OTP on
  const [loginEmail, setLoginEmail] = useState('');

  // Loading states
  const [sendingOtps, setSendingOtps] = useState(false);
  const [loggingIn, setLoggingIn] = useState(false);
  const [consenting, setConsenting] = useState(false);
  const [consentError, setConsentError] = useState<string | null>(null);
  const [loginError, setLoginError] = useState<string | null>(null);
  const navigate = useNavigate();

  const submitOtp = async (email: string, otp: string) => {
    try {
      setLoggingIn(true);
      setLoginError(null);

      try {
        if (config.useCognito) {
          await cognitoVerifyOtpAction(otp);
        } else {
          const verifyOtpResponse = await verifyOtpResponseAction(email, otp);

          if (!verifyOtpResponse?.jwt) {
            throw new Error('No JWT returned');
          } else {
            setAuthState({ jwt: verifyOtpResponse.jwt });
            await resetMeQuery();
          }
        }
      } catch (error) {
        if (
          error instanceof Error &&
          error.message === 'Invalid code for email'
        ) {
          setLoginError('OTP is invalid');

          return;
        }

        throw error;
      }

      navigate('/dashboard', {
        state: {
          doRedirect: true
        }
      });
    } catch (error) {
      console.error(error);
      captureException(error);
      toast.error('Failed to login');
    } finally {
      setLoggingIn(false);
    }
  };

  const requestOtp = async (email: string) => {
    try {
      setSendingOtps(true);

      if (config.useCognito) {
        await cognitoRequestOtpAction(email);
      } else {
        await requestOtpExistingUserDEVAction(email);
      }

      setOtpModalIsOpen(true);
    } catch (error) {
      console.error(error);
      captureException(error);
      toast.error('Failed to request OTP');
    } finally {
      setSendingOtps(false);
    }
  };

  const doConsent = async (email: string, phoneNumber: string) => {
    try {
      setConsenting(true);

      try {
        await recordConsentAction(email, phoneNumber);
      } catch (error) {
        if (error instanceof Error) {
          if (error.message.includes('is not a valid phone number')) {
            setConsentError('The phone number is invalid');

            return;
          } else if (error.message.includes('Phone number does not match')) {
            setConsentError('The phone number does not belong to the account');

            return;
          }
        }

        throw error;
      }
      // Yay, the uesr has consented!
      await requestOtp(email);
      setConsentModalIsOpen(false);
    } catch (error) {
      console.error(error);
      captureException(error);
      toast.error('Failed to record consent');
    } finally {
      setConsenting(false);
    }
  };

  const submitEmail = async (email: string) => {
    try {
      setLoginEmail(email);
      // If user has consented
      const hasConsented = await gqlMutationClient()({
        consentCheck: [
          {
            email
          },
          {
            __typename: true,
            '...on GraphQLError': { message: true },
            '...on MutationConsentCheckSuccess': {
              data: { hasConsentedToSms: true, userExists: true }
            }
          }
        ]
      });

      if (hasConsented.consentCheck?.__typename === 'GraphQLError') {
        setLoginError(hasConsented.consentCheck.message ?? 'Unexpected error');
        throw new Error(
          hasConsented.consentCheck.message ?? 'Unexpected error'
        );
      }

      if (!hasConsented.consentCheck?.data.userExists) {
        setLoginError('No user on the provided email');
        throw new Error('No user on the provided email');
      }

      if (!hasConsented.consentCheck?.data.hasConsentedToSms) {
        // The user did not consent
        setConsentModalIsOpen(true);
      } else {
        await requestOtp(email);
      }
    } catch (error) {
      console.error(error);
      captureException(error);
      toast.error('Failed to check consent');
    } finally {
      setSendingOtps(false);
    }
  };

  const formLogin = useForm<{ email: string }, unknown>({
    defaultValues: {
      email: ''
    },
    onSubmit: async ({ email }) => {
      await submitEmail(email);
    }
  });

  const formOtp = useForm<{ otp: string }, unknown>({
    defaultValues: {
      otp: ''
    }
  });

  const formConsent = useForm({
    defaultValues: {
      phoneNumber: ''
    }
  });

  return (
    <div className="flex flex-col items-center bg-primary">
      <div
        className={classNames(
          'flex flex-col items-center w-screen space-y-12 p-10',
          'sm:p-12',
          'md:p-14',
          'lg:p-16'
        )}
      >
        <img
          src="/logo.png"
          className={classNames(
            'h-16 object-contain',
            'sm:h-20',
            'md:h-24',
            'lg:h-28',
            'xl:h-32',
            '2xl:h-36'
          )}
        />
        {/* Consent Modals */}
        <Modal
          isOpen={consentModalIsOpen}
          title="Verification"
          className="p-6 mx-10"
          onClose={() => {
            setConsentModalIsOpen(false);
          }}
        >
          <formConsent.Provider>
            <form className="flex flex-col items-center space-y-6">
              <div className="space-y-4">
                <p>
                  I consent to receiving text messages and emails from TDC. I
                  acknowledge my responsibility to cover any text messaging and
                  data charges imposed by my mobile service provider, if
                  applicable.
                </p>
                <p>
                  Please enter the phone number your account has been created
                  on.
                </p>
              </div>

              <formConsent.Field name="phoneNumber">
                {(field) => (
                  <PhoneInput
                    defaultCountry="US"
                    placeholder={'Phone Number'}
                    value={field.state.value}
                    name={field.name}
                    className="border-b-[0.5px] h-8 px-2 space-x-2 outline-none placeholder:text-inactive w-full"
                    onChange={(e) => {
                      field.handleChange(e?.toString() ?? '');
                    }}
                    data-test-id={'consumer-sign-in-phone'}
                  />
                )}
              </formConsent.Field>

              {consentError && (
                <div className="bg-red-400 text-white rounded-md w-full p-2 text-center">
                  {consentError}
                </div>
              )}

              <formConsent.Subscribe>
                {(fields) => (
                  <Button
                    type="submit"
                    loading={consenting}
                    onClick={() =>
                      doConsent(
                        formLogin.getFieldValue('email'),
                        fields.values['phoneNumber']
                      )
                    }
                    dataTestId="consumer-sign-in-phonenumber-submit"
                  >
                    I Consent
                  </Button>
                )}
              </formConsent.Subscribe>
            </form>
          </formConsent.Provider>
        </Modal>

        {/* OTP Modal */}
        <Modal
          isOpen={otpModalIsOpen}
          title="Verification"
          className="p-6 mx-10"
          onClose={() => {
            setOtpModalIsOpen(false);
          }}
        >
          <formOtp.Provider>
            <formOtp.Subscribe
              selector={(state) => [state.values.otp]}
              children={([otp]) => {
                return (
                  <form
                    className="flex flex-col items-center space-y-6"
                    onSubmit={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      submitOtp(loginEmail, otp);
                    }}
                  >
                    <div className="space-y-4">
                      <p>
                        We have sent you a code via email and text message.
                        Enter it here to login.
                      </p>

                      <formOtp.Field name="otp">
                        {(field) => {
                          return (
                            <TextInput
                              dataTestId="consumer-sign-in-otp"
                              fieldName={field.name}
                              value={field.state.value}
                              placeholder="Email Code"
                              labelText="Email Code"
                              subtitleText="Email Code"
                              error={
                                loginError
                                  ? 'Invalid code'
                                  : field.state.meta.touchedErrors.at(0)
                              }
                              onChange={(e) => {
                                field.handleChange(e.target.value);
                              }}
                            />
                          );
                        }}
                      </formOtp.Field>
                    </div>

                    <Button
                      dataTestId="consumer-sign-in-otp-submit"
                      type="submit"
                      loading={loggingIn}
                      disabled={otp.length !== 6}
                    >
                      Submit
                    </Button>
                  </form>
                );
              }}
            />
          </formOtp.Provider>
        </Modal>

        <formLogin.Provider>
          <Fragment>
            <form
              onSubmit={(e) => {
                e.preventDefault();
                e.stopPropagation();
                void formLogin.handleSubmit();
              }}
              className="w-full max-w-lg shadow-lg rounded-xl p-6 md:p-12 flex flex-col space-y-6 items-center bg-primary"
            >
              <h1>Login</h1>

              <div className="h-8 w-full">
                {loginError && (
                  <div className="rounded-md bg-negative-primary text-primary-inverse size-full flex items-center justify-center">
                    <p>{loginError}</p>
                  </div>
                )}
              </div>

              <div className="mt-10 w-full">
                <formLogin.Field name="email">
                  {(field) => {
                    return (
                      <TextInput
                        dataTestId="consumer-sign-in-email"
                        fieldName={field.name}
                        value={field.state.value}
                        isEmail
                        labelText="Email"
                        placeholder="Email"
                        subtitleText="Email"
                        error={field.state.meta.touchedErrors.at(0)}
                        onChange={(e) => {
                          field.handleChange(
                            e.target.value.toLocaleLowerCase().trim()
                          );
                        }}
                      />
                    );
                  }}
                </formLogin.Field>
              </div>

              <formLogin.Subscribe selector={(state) => [state.values.email]}>
                {([email]) => {
                  return (
                    <Button
                      dataTestId="consumer-sign-in-submit-button"
                      type="submit"
                      disabled={!email.length}
                      loading={sendingOtps}
                    >
                      Log in
                    </Button>
                  );
                }}
              </formLogin.Subscribe>
            </form>
            <div className="flex flex-row space-x-6">
              <a
                className="text-blue-500 hover:underline"
                href="https://files.mytdc.net/terms-and-conditions-tdc-digital-dealer-dervices-rev-jan-2024.pdf"
                target="_blank"
              >
                Terms & Conditions
              </a>
              <a
                className="text-blue-500 hover:underline"
                href="https://files.mytdc.net/privacy-policy-dealergenix-jan2024.pdf"
                target="_blank"
              >
                Privacy Policy
              </a>
            </div>
          </Fragment>
        </formLogin.Provider>
      </div>
    </div>
  );
}
