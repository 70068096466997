import { useQuery } from '@tanstack/react-query';
import { useAtomValue } from 'jotai';
import {
  ChangeEvent,
  FC,
  Fragment,
  MutableRefObject,
  ReactNode,
  useCallback,
  useRef,
  useState
} from 'react';
import { isMobile } from 'react-device-detect';
import { VehicleType } from '~/__generated__/backend/zeus';
import uploadFileAction from '~/actions/formSubmissions/uploadFileAction';
import updateVehicleAction from '~/actions/vehicles/updateVehicleAction';
import Button from '~/components/Button';
import Header from '~/components/Header';
import Spinner from '~/components/Spinner';
import CameraIcon from '~/components/icons/CameraIcon';
import CaptureIcon from '~/components/icons/CaptureIcon';
import CarBackIcon from '~/components/icons/CarBackIcon';
import CarFrontIcon from '~/components/icons/CarFrontIcon';
import CarInspectionIcon from '~/components/icons/CarInspectionIcon';
import CarOdometerIcon from '~/components/icons/CarOdometerIcon';
import CarSeatIcon from '~/components/icons/CarSeatIcon';
import CarSideIcon from '~/components/icons/CarSideIcon';
import ChevronRightIcon from '~/components/icons/ChevronRightIcon';
import UploadIcon from '~/components/icons/UploadIcon';
import { gqlQueryClient } from '~/lib/backend';
import { queryClient } from '~/main';
import meQuery, { resetMeQuery } from '~/queries/meQuery';
import { vehicleSelector } from '~/querySelectors/vehicle';
import { Link, useNavigate, useParams } from '~/router';
import { kioskDealershipAtom } from '~/state/kiosk';

type PictureType =
  | 'front'
  | 'back'
  | 'leftSide'
  | 'rightSide'
  | 'interior'
  | 'odometer'
  | 'vinNumber';

const VehiclePicture: FC<{
  title: string;
  pictureUrl?: string;
  captureCenterIcon: ReactNode;
  uploadingFile: boolean;
  deletingFile: boolean;
  fileInputRef: MutableRefObject<HTMLInputElement | null>;
  onChangeCapture: (e: ChangeEvent<HTMLInputElement>) => void;
  onUploadFile: () => void;
  onUseCamera: () => void;
  onDeletePictrue: () => void;
  enableCamera: boolean;
  disabled?: boolean;
}> = ({
  title,
  pictureUrl,
  captureCenterIcon,
  uploadingFile,
  deletingFile,
  fileInputRef,
  onChangeCapture,
  onUploadFile,
  onUseCamera,
  onDeletePictrue,
  enableCamera,
  disabled
}) => {
  return (
    <div className="flex flex-col space-y-2 w-full">
      <div>{title}</div>

      {!pictureUrl ? (
        <div className="flex flex-col space-y-4 icon-tertiary">
          <CaptureIcon
            className="w-full"
            centerIcon={
              uploadingFile ? (
                <div className="flex w-full h-20 items-center justify-center">
                  <Spinner />
                </div>
              ) : (
                <div className="flex relative justify-center">
                  {captureCenterIcon}
                </div>
              )
            }
          />

          <div className="flex flex-row justify-center space-x-2">
            <div className="flex">
              <input
                type="file"
                ref={fileInputRef}
                className="hidden"
                accept="image/*"
                onChangeCapture={onChangeCapture}
              />

              <Button
                size="SMALL"
                variant="SECONDARY"
                onClick={onUploadFile}
                disabled={uploadingFile || disabled}
              >
                <div className="flex flex-row items-center space-x-2">
                  <div className="relative">
                    <UploadIcon className="w-4" />
                  </div>

                  <p className="text-body-2">Upload</p>
                </div>
              </Button>
            </div>

            {enableCamera && (
              <Button
                size="SMALL"
                variant="SECONDARY"
                onClick={onUseCamera}
                disabled={uploadingFile || disabled}
              >
                <div className="flex flex-row items-center space-x-1">
                  <div className="relative">
                    <CameraIcon className="w-6" />
                  </div>

                  <p className="text-body-2">Camera</p>
                </div>
              </Button>
            )}
          </div>
        </div>
      ) : (
        <div className="flex flex-col space-y-2">
          <img
            src={pictureUrl}
            className="rounded-lg aspect-[3/2]"
            style={{ objectFit: 'cover' }}
          />

          <Button
            variant="TEXT_ONLY"
            onClick={onDeletePictrue}
            loading={deletingFile}
          >
            <span className="text-tertiary">Delete</span>
          </Button>
        </div>
      )}
    </div>
  );
};

const VehiclePicturesPage = () => {
  const mobileCameraInputRef = useRef<HTMLInputElement | null>(null);
  const kioskMode = useAtomValue(kioskDealershipAtom);
  const { transactionId, vehicleId, dealershipSlug } = useParams(
    '/dashboard/:dealershipSlug/:transactionId/tradeVehicle/:vehicleId/pictures'
  );
  const [type, setType] = useState<PictureType | undefined>(undefined);
  const [uploadingFile, setUploadingFile] = useState(false);
  const [deletingFile, setDeletingFile] = useState(false);
  const fileInputRefFront = useRef<HTMLInputElement | null>(null);
  const fileInputRefBack = useRef<HTMLInputElement | null>(null);
  const fileInputRefLeftSide = useRef<HTMLInputElement | null>(null);
  const fileInputRefRightSide = useRef<HTMLInputElement | null>(null);
  const fileInputRefInterior = useRef<HTMLInputElement | null>(null);
  const fileInputRefOdometer = useRef<HTMLInputElement | null>(null);
  const fileInputRefVinNumber = useRef<HTMLInputElement | null>(null);
  const navigate = useNavigate();
  const { data: userData, isLoading: loadingUserData } = useQuery(meQuery());
  const dId = 'dealershipId(meData)';
  const { data: vehicleData, isLoading: loadingVehicleData } = useQuery({
    queryKey: ['transaction', transactionId, 'tradeVehicle', vehicleId],
    queryFn: async () =>
      gqlQueryClient()({
        transaction: [
          {
            id: transactionId
          },
          {
            tradeVehicle: vehicleSelector
          }
        ]
      })
  });
  const handleCancel = () => {
    navigate('/dashboard/:dealershipSlug/:transactionId', {
      params: { transactionId, dealershipSlug }
    });
  };
  const handleGoBack = () => {
    navigate(
      '/dashboard/:dealershipSlug/:transactionId/tradeVehicle/:vehicleId/payoff',
      {
        params: { transactionId, vehicleId, dealershipSlug }
      }
    );
  };
  const handleDone = () => {
    navigate('/dashboard/:dealershipSlug/:transactionId', {
      params: { transactionId, dealershipSlug }
    });
  };
  const handleUseCamera = (pictureType: PictureType) => {
    setType(pictureType);
    mobileCameraInputRef.current?.click();
  };
  const handleUploadFile = (
    fileInputRef: MutableRefObject<HTMLInputElement | null>
  ) => {
    fileInputRef.current?.click();
  };
  const uploadVehiclePicture = useCallback(
    async (file: File) => {
      const fileIsPng = file.type.includes('png');
      const uploadedFile = await uploadFileAction(
        fileIsPng ? 'png' : 'jpg',
        fileIsPng ? 'image/png' : 'image/jpg',
        file
      );

      const updatedVehicle: {
        pictureFrontId?: string;
        pictureBackId?: string;
        pictureLeftSideId?: string;
        pictureRightSideId?: string;
        pictureInteriorId?: string;
        pictureOdometerId?: string;
        pictureVinNumberId?: string;
      } = {};

      switch (type) {
        case 'front':
          updatedVehicle['pictureFrontId'] = uploadedFile?.fileId;
          break;
        case 'back':
          updatedVehicle['pictureBackId'] = uploadedFile?.fileId;
          break;
        case 'leftSide':
          updatedVehicle['pictureLeftSideId'] = uploadedFile?.fileId;
          break;
        case 'rightSide':
          updatedVehicle['pictureRightSideId'] = uploadedFile?.fileId;
          break;
        case 'interior':
          updatedVehicle['pictureInteriorId'] = uploadedFile?.fileId;
          break;
        case 'odometer':
          updatedVehicle['pictureOdometerId'] = uploadedFile?.fileId;
          break;
        case 'vinNumber':
          updatedVehicle['pictureVinNumberId'] = uploadedFile?.fileId;
          break;
      }

      await updateVehicleAction(
        transactionId,
        VehicleType.TRADE,
        vehicleId,
        updatedVehicle
      );
    },
    [transactionId, dId, type, vehicleId]
  );
  const handleFileChangeCapture = useCallback(
    async (e: ChangeEvent<HTMLInputElement>) => {
      const file = e.target.files?.item(0);

      if (file && type) {
        setUploadingFile(true);

        await uploadVehiclePicture(file);
        await Promise.all([
          await resetMeQuery(),
          await queryClient.resetQueries({
            queryKey: ['transaction', transactionId]
          })
        ]);

        setUploadingFile(false);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [type, userData]
  );
  const handleDeletePicture = async (
    type:
      | 'front'
      | 'back'
      | 'leftSide'
      | 'rightSide'
      | 'interior'
      | 'odometer'
      | 'vinNumber'
  ) => {
    setDeletingFile(true);

    const updatedVehicle: {
      pictureFrontId?: string | null;
      pictureBackId?: string | null;
      pictureLeftSideId?: string | null;
      pictureRightSideId?: string | null;
      pictureInteriorId?: string | null;
      pictureOdometerId?: string | null;
      pictureVinNumberId?: string | null;
    } = {};

    switch (type) {
      case 'front':
        updatedVehicle['pictureFrontId'] = null;
        break;
      case 'back':
        updatedVehicle['pictureBackId'] = null;
        break;
      case 'leftSide':
        updatedVehicle['pictureLeftSideId'] = null;
        break;
      case 'rightSide':
        updatedVehicle['pictureRightSideId'] = null;
        break;
      case 'interior':
        updatedVehicle['pictureInteriorId'] = null;
        break;
      case 'odometer':
        updatedVehicle['pictureOdometerId'] = null;
        break;
      case 'vinNumber':
        updatedVehicle['pictureVinNumberId'] = null;
        break;
    }

    await updateVehicleAction(
      transactionId,
      VehicleType.TRADE,
      vehicleId,
      updatedVehicle
    );

    await Promise.all([
      await resetMeQuery(),
      await queryClient.resetQueries({
        queryKey: ['transaction', transactionId]
      })
    ]);

    setDeletingFile(false);
  };
  const handleMobileImageCapture = useCallback(
    async (event: ChangeEvent<HTMLInputElement>) => {
      if (event.target.files && event.target.files.length > 0) {
        setUploadingFile(true);

        const capture = event.target.files[0];

        await uploadVehiclePicture(capture);
        await Promise.all([
          await resetMeQuery(),
          await queryClient.resetQueries({
            queryKey: ['transaction', transactionId]
          })
        ]);

        setUploadingFile(false);
      }
    },
    [transactionId, uploadVehiclePicture]
  );
  const enableCamera = isMobile && !kioskMode; // We only allow the camera for mobiles and tablets that aren't in kiosk mode

  return (
    <div className="flex flex-col h-dvh">
      <Header
        title="Trade Registration"
        leftElement={
          <button className=" text-primary-brand" onClick={handleCancel}>
            Cancel
          </button>
        }
        totalSteps={4}
        currentStep={4}
      />

      <div className="flex w-full px-6 justify-center z-30">
        <div className="flex w-full max-w-screen-md self-center pt-4">
          <Link
            to="/dashboard/:dealershipSlug/:transactionId/tradeVehicle/:vehicleId/payoff"
            params={{ transactionId, vehicleId, dealershipSlug }}
            className="flex w-6"
          >
            <div className="relative">
              <ChevronRightIcon className="w-6 -scale-x-100 icon-tertiary" />
            </div>
          </Link>
        </div>
      </div>

      {/**
       * This input opens the camera on mobile devices
       */}
      <input
        ref={mobileCameraInputRef}
        accept="image/*"
        type="file"
        capture="environment"
        className="hidden"
        onChange={handleMobileImageCapture}
      />

      <div className="flex flex-col items-center overflow-y-scroll px-6">
        <div className="flex flex-col max-w-screen-md py-6 md:py-10 space-y-6 md:space-y-12 justify-between w-full">
          <h2>Step 4: Pictures</h2>

          <p>Take or upload pictures of of your car.</p>

          {(loadingUserData || loadingVehicleData) && (
            <div className="flex flex-1 items-center justify-center">
              <Spinner />
            </div>
          )}

          {!loadingUserData && !loadingVehicleData && (
            <Fragment>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
                <VehiclePicture
                  title="Front"
                  pictureUrl={
                    vehicleData?.transaction?.tradeVehicle?.pictureFront?.url
                  }
                  captureCenterIcon={<CarFrontIcon className="h-20  " />}
                  uploadingFile={uploadingFile && type === 'front'}
                  deletingFile={deletingFile && type === 'front'}
                  fileInputRef={fileInputRefFront}
                  onChangeCapture={handleFileChangeCapture}
                  onUploadFile={() => {
                    setType('front');
                    handleUploadFile(fileInputRefFront);
                  }}
                  onUseCamera={() => {
                    handleUseCamera('front');
                  }}
                  onDeletePictrue={() => {
                    setType('front');
                    handleDeletePicture('front');
                  }}
                  enableCamera={enableCamera}
                  disabled={uploadingFile}
                />

                <VehiclePicture
                  title="Back"
                  pictureUrl={
                    vehicleData?.transaction?.tradeVehicle?.pictureBack?.url
                  }
                  captureCenterIcon={<CarBackIcon className="h-20  " />}
                  uploadingFile={uploadingFile && type === 'back'}
                  deletingFile={deletingFile && type === 'back'}
                  fileInputRef={fileInputRefBack}
                  onChangeCapture={handleFileChangeCapture}
                  onUploadFile={() => {
                    setType('back');
                    handleUploadFile(fileInputRefBack);
                  }}
                  onUseCamera={() => {
                    handleUseCamera('back');
                  }}
                  onDeletePictrue={() => {
                    setType('back');
                    handleDeletePicture('back');
                  }}
                  enableCamera={enableCamera}
                  disabled={uploadingFile}
                />

                <VehiclePicture
                  title="Left Side"
                  pictureUrl={
                    vehicleData?.transaction?.tradeVehicle?.pictureLeftSide?.url
                  }
                  captureCenterIcon={<CarSideIcon className="h-20  " />}
                  uploadingFile={uploadingFile && type === 'leftSide'}
                  deletingFile={deletingFile && type === 'leftSide'}
                  fileInputRef={fileInputRefLeftSide}
                  onChangeCapture={handleFileChangeCapture}
                  onUploadFile={() => {
                    setType('leftSide');
                    handleUploadFile(fileInputRefLeftSide);
                  }}
                  onUseCamera={() => {
                    handleUseCamera('leftSide');
                  }}
                  onDeletePictrue={() => {
                    setType('leftSide');
                    handleDeletePicture('leftSide');
                  }}
                  enableCamera={enableCamera}
                  disabled={uploadingFile}
                />

                <VehiclePicture
                  title="Right Side"
                  pictureUrl={
                    vehicleData?.transaction?.tradeVehicle?.pictureRightSide
                      ?.url
                  }
                  captureCenterIcon={
                    <CarSideIcon className="h-20  -scale-x-100" />
                  }
                  uploadingFile={uploadingFile && type === 'rightSide'}
                  deletingFile={deletingFile && type === 'rightSide'}
                  fileInputRef={fileInputRefRightSide}
                  onChangeCapture={handleFileChangeCapture}
                  onUploadFile={() => {
                    setType('rightSide');
                    handleUploadFile(fileInputRefRightSide);
                  }}
                  onUseCamera={() => {
                    handleUseCamera('rightSide');
                  }}
                  onDeletePictrue={() => {
                    setType('rightSide');
                    handleDeletePicture('rightSide');
                  }}
                  enableCamera={enableCamera}
                  disabled={uploadingFile}
                />

                <VehiclePicture
                  title="Interior"
                  pictureUrl={
                    vehicleData?.transaction?.tradeVehicle?.pictureInterior?.url
                  }
                  captureCenterIcon={<CarSeatIcon className="h-20  " />}
                  uploadingFile={uploadingFile && type === 'interior'}
                  deletingFile={deletingFile && type === 'interior'}
                  fileInputRef={fileInputRefInterior}
                  onChangeCapture={handleFileChangeCapture}
                  onUploadFile={() => {
                    setType('interior');
                    handleUploadFile(fileInputRefInterior);
                  }}
                  onUseCamera={() => {
                    handleUseCamera('interior');
                  }}
                  onDeletePictrue={() => {
                    setType('interior');
                    handleDeletePicture('interior');
                  }}
                  enableCamera={enableCamera}
                  disabled={uploadingFile}
                />

                <VehiclePicture
                  title="Odometer"
                  pictureUrl={
                    vehicleData?.transaction?.tradeVehicle?.pictureOdometer?.url
                  }
                  captureCenterIcon={<CarOdometerIcon className="h-20  " />}
                  uploadingFile={uploadingFile && type === 'odometer'}
                  deletingFile={deletingFile && type === 'odometer'}
                  fileInputRef={fileInputRefOdometer}
                  onChangeCapture={handleFileChangeCapture}
                  onUploadFile={() => {
                    setType('odometer');
                    handleUploadFile(fileInputRefOdometer);
                  }}
                  onUseCamera={() => {
                    handleUseCamera('odometer');
                  }}
                  onDeletePictrue={() => {
                    setType('odometer');
                    handleDeletePicture('odometer');
                  }}
                  enableCamera={enableCamera}
                  disabled={uploadingFile}
                />

                <VehiclePicture
                  title="VIN Number (Side of the Driver's Door)"
                  pictureUrl={
                    vehicleData?.transaction?.tradeVehicle?.pictureVinNumber
                      ?.url
                  }
                  captureCenterIcon={<CarInspectionIcon className="h-20  " />}
                  uploadingFile={uploadingFile && type === 'vinNumber'}
                  deletingFile={deletingFile && type === 'vinNumber'}
                  fileInputRef={fileInputRefVinNumber}
                  onChangeCapture={handleFileChangeCapture}
                  onUploadFile={() => {
                    setType('vinNumber');
                    handleUploadFile(fileInputRefVinNumber);
                  }}
                  onUseCamera={() => {
                    handleUseCamera('vinNumber');
                  }}
                  onDeletePictrue={() => {
                    setType('vinNumber');
                    handleDeletePicture('vinNumber');
                  }}
                  enableCamera={enableCamera}
                  disabled={uploadingFile}
                />
              </div>

              <div className="flex flex-row justify-between">
                <Button
                  variant="TERTIARY"
                  onClick={handleGoBack}
                  disabled={uploadingFile}
                >
                  Back
                </Button>

                <Button onClick={handleDone} disabled={uploadingFile}>
                  Done
                </Button>
              </div>
            </Fragment>
          )}
        </div>
      </div>
    </div>
  );
};

export default VehiclePicturesPage;
