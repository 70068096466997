import { UpdateVehicleSchema } from '@thedealersconcierge/lib/codecs/schema/vehicle';
import { VehicleType } from '~/__generated__/backend/zeus';
import { gqlMutationClient } from '~/lib/backend';

const updateVehicleAction = async (
  transactionId: string,
  vehicleType: VehicleType,
  vehicleId: string,
  vehicle: UpdateVehicleSchema
) => {
  const resp = await gqlMutationClient()({
    updateVehicle: [
      {
        transactionId,
        vehicleType,
        vehicleId,
        vehicle
      },
      {
        __typename: true,
        '...on MutationUpdateVehicleSuccess': {
          data: {
            status: true
          }
        },
        '...on GraphQLError': {
          message: true
        }
      }
    ]
  });

  if (!resp.updateVehicle || resp.updateVehicle.__typename === 'GraphQLError') {
    throw new Error(resp.updateVehicle?.message ?? 'Unexpected error');
  }
};

export default updateVehicleAction;
