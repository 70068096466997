import * as Sentry from '@sentry/react';
import { useForm } from '@tanstack/react-form';
import { useQuery } from '@tanstack/react-query';
import classNames from 'classnames';
import { FC, Fragment, useState } from 'react';
import { toast } from 'react-toastify';
import submitPreQualApplicationAction from '~/actions/formSubmissions/prequal/submitPreQualApplicationAction';
import Button from '~/components/Button';
import Signature from '~/components/Signature';
import Checkbox from '~/components/inputs/Checkbox';
import DateInput from '~/components/inputs/DateInput';
import DropDown from '~/components/inputs/DropDown';
import SocialSecurityNumberInput from '~/components/inputs/SocialSecurityNumberInput';
import TextInput from '~/components/inputs/TextInput';
import config from '~/config';
import stateOptions from '~/config/formSelectionOptions/stateOptions';
import { getDateFromUnkown } from '~/lib/utils';
import customerQuery from '~/queries/customerQuery';
import dealershipQuery from '~/queries/dealershipQuery';
import { MeQueryType } from '~/queries/meQuery';
import { TransactionQueryType } from '~/queries/transactionQuery';

const PreQualForm: FC<{
  transactionId: string;
  isSubmitting: boolean;
  meData?: MeQueryType;
  transactionData?: TransactionQueryType;
  setIsSubmitting: React.Dispatch<React.SetStateAction<boolean>>;
  onSuccess: () => void;
  dealershipSlug: string;
}> = ({
  transactionId,
  isSubmitting,
  meData,
  transactionData,
  setIsSubmitting,
  onSuccess,
  dealershipSlug
}) => {
  const [error, setError] = useState<string | undefined>(undefined);
  const [signature, setSignature] = useState<string | null>(null);
  const { data: customerData } = useQuery(
    customerQuery(transactionId, meData?.me?.user?.id, dealershipSlug)
  );
  const cust = customerData?.customer;

  const residentialAddress = cust?.residentialAddresses?.edges?.find(
    (e) => e.node?.timelinePosition === 0
  )?.node;
  const user = meData?.me?.user;

  const { data: dealershipData } = useQuery(
    dealershipQuery({ id: transactionData?.transaction?.dealership?.id })
  );
  const form = useForm({
    defaultValues: {
      firstName: cust?.firstName ?? user?.firstName ?? '',
      middleName: cust?.middleName ?? '',
      lastName: cust?.lastName ?? user?.lastName ?? '',
      birthdate: getDateFromUnkown(cust?.birthdate) ?? null,
      socialSecurityNumber: cust?.socialSecurityNumber ?? '',
      addressStreet: residentialAddress?.street ?? '',
      addressApartmentDetails: residentialAddress?.apartmentDetails ?? '',
      addressZipCode: residentialAddress?.zipCode ?? '',
      addressCity: residentialAddress?.city ?? '',
      addressState: residentialAddress?.state?.toLocaleLowerCase() ?? '',
      acknowledgesInformationUnderstanding: false,
      understandsCreditScoreAffection: false,
      acknowledgesPrivacyNotice: false,
      providedInstructions: false
    },
    onSubmit: async (values) => {
      try {
        // Mostly for types, as the form is deactivated when not signed.
        if (!signature) {
          alert('Please sign');
          return;
        }

        // This is for typing. Submit is disabled if birthdate is not set
        if (!values.birthdate) {
          toast.error('Birthdate is missing');
          return;
        }

        setError(undefined);
        setIsSubmitting(true);

        await submitPreQualApplicationAction(
          { ...values, birthdate: values.birthdate }, // Have to use the spread operator for the type system to pick up that we checked that the birthdate is defined
          dealershipData?.dealership?.name ?? 'No name',
          meData?.me?.ipAddress ?? 'Unknown IP',
          signature,
          transactionId
        );

        return onSuccess();
      } catch (e: any) {
        Sentry.captureException(error);
        console.error(error);
        setError(error);
      } finally {
        setIsSubmitting(false);
      }
    }
  });

  const dealership = dealershipData?.dealership;

  return (
    <form.Provider>
      <form
        className="flex flex-col w-full"
        onSubmit={(e) => {
          e.preventDefault();
          e.stopPropagation();
          void form.handleSubmit();
        }}
      >
        <div className="flex w-full px-6 pb-6 justify-center">
          <div className="flex flex-col items-start w-full max-w-screen-md">
            <form.Field
              name="acknowledgesInformationUnderstanding"
              children={(field) => {
                return (
                  <div
                    onClick={() => field.handleChange(!field.state.value)}
                    className="flex flex-row space-x-2 items-center cursor-pointer"
                  >
                    <Checkbox
                      inputId={field.name}
                      value={field.state.value}
                      onChange={() => field.handleChange(!field.state.value)}
                      disabled={isSubmitting}
                      dataTestId="prequal-app-ackbnowlage-checkbox"
                    />
                    <p className="text-secondary">I acknowledge and agree.</p>
                  </div>
                );
              }}
            />
          </div>
        </div>

        <form.Subscribe selector={(state) => [state.values]}>
          {([values]) => {
            const canContinue =
              !!values.firstName &&
              !!values.lastName &&
              !!values.birthdate &&
              !!values.socialSecurityNumber &&
              !!values.addressStreet &&
              !!values.addressZipCode &&
              !!values.addressCity &&
              !!values.addressState &&
              !!values.acknowledgesPrivacyNotice &&
              !!values.understandsCreditScoreAffection &&
              !!values.providedInstructions &&
              signature;

            return (
              <Fragment>
                <div className="flex w-full border-y border-secondary bg-secondary p-6 justify-center">
                  <div className="flex flex-col items-start w-full max-w-screen-md space-y-6">
                    <h2
                      className={classNames({
                        'text-inactive':
                          !values.acknowledgesInformationUnderstanding
                      })}
                    >
                      Basic Information
                    </h2>

                    <div className="grid grid-cols-2 md:grid-cols-8 gap-4 md:items-end w-full">
                      <form.Field name="firstName">
                        {(field) => {
                          return (
                            <TextInput
                              fieldName={field.name}
                              value={field.state.value}
                              labelText="First Name"
                              placeholder="First Name"
                              subtitleText="First Name"
                              required
                              disabled={
                                isSubmitting ||
                                !values.acknowledgesInformationUnderstanding
                              }
                              error={field.state.meta.touchedErrors.at(0)}
                              onChange={(e) =>
                                field.handleChange(e.target.value)
                              }
                              containerClassName="md:col-span-3"
                              dataTestId="prequal-app-firstaname-input"
                            />
                          );
                        }}
                      </form.Field>

                      <form.Field name="middleName">
                        {(field) => {
                          return (
                            <TextInput
                              fieldName={field.name}
                              value={field.state.value}
                              labelText="Middle Name"
                              subtitleText="Middle Name"
                              placeholder="Middle Name"
                              disabled={
                                isSubmitting ||
                                !values.acknowledgesInformationUnderstanding
                              }
                              error={field.state.meta.touchedErrors.at(0)}
                              onChange={(e) =>
                                field.handleChange(e.target.value)
                              }
                              containerClassName="md:col-span-2"
                              dataTestId="prequal-app-middlename-input"
                            />
                          );
                        }}
                      </form.Field>

                      <form.Field name="lastName">
                        {(field) => {
                          return (
                            <TextInput
                              fieldName={field.name}
                              value={field.state.value}
                              labelText="Last Name"
                              placeholder="Last Name"
                              subtitleText="Last Name"
                              required
                              disabled={
                                isSubmitting ||
                                !values.acknowledgesInformationUnderstanding
                              }
                              error={field.state.meta.touchedErrors.at(0)}
                              onChange={(e) =>
                                field.handleChange(e.target.value)
                              }
                              containerClassName="md:col-span-3"
                              dataTestId="prequal-app-lastname-input"
                            />
                          );
                        }}
                      </form.Field>

                      <form.Field name="birthdate">
                        {(field) => {
                          return (
                            <DateInput
                              fieldName={field.name}
                              value={field.state.value}
                              subtitleText="Your Date of Birth"
                              placeholderText="Birthdate"
                              required
                              disabled={
                                isSubmitting ||
                                !values.acknowledgesInformationUnderstanding
                              }
                              error={field.state.meta.touchedErrors.at(0)}
                              onChange={(updatedDate) => {
                                field.handleChange(updatedDate);
                              }}
                              containerClassName="md:col-span-4"
                              dataTestId="prequal-app-birthdate-input"
                            />
                          );
                        }}
                      </form.Field>

                      <form.Field name="socialSecurityNumber">
                        {(field) => {
                          return (
                            <SocialSecurityNumberInput
                              value={field.state.value}
                              fieldName={field.name}
                              subtitleText="Social Security Number"
                              placeholder="Social Security Number"
                              disabled={
                                isSubmitting ||
                                !values.acknowledgesInformationUnderstanding
                              }
                              required
                              onBlur={field.handleBlur}
                              onChange={(e) =>
                                field.handleChange(e.target.value)
                              }
                              setFieldValue={(_fieldName, value) =>
                                form.setFieldValue(field.name, value)
                              }
                              containerClassName="col-span-2 md:col-span-4"
                              dataTestId="prequal-app-ssn-input"
                            />
                          );
                        }}
                      </form.Field>

                      <form.Field name="addressStreet">
                        {(field) => {
                          return (
                            <TextInput
                              fieldName={field.name}
                              value={field.state.value}
                              labelText="Street Address"
                              placeholder="Street Address"
                              subtitleText="Street Address"
                              required
                              disabled={
                                isSubmitting ||
                                !values.acknowledgesInformationUnderstanding
                              }
                              error={field.state.meta.touchedErrors.at(0)}
                              onChange={(e) =>
                                field.handleChange(e.target.value)
                              }
                              containerClassName="col-span-2 md:col-span-4"
                              dataTestId="prequal-app-addressStreet-input"
                            />
                          );
                        }}
                      </form.Field>

                      <form.Field name="addressApartmentDetails">
                        {(field) => {
                          return (
                            <TextInput
                              fieldName={field.name}
                              value={field.state.value}
                              labelText="Apartment Details"
                              placeholder="Suite, Apartment #"
                              subtitleText="Apartment Details"
                              disabled={
                                isSubmitting ||
                                !values.acknowledgesInformationUnderstanding
                              }
                              error={field.state.meta.touchedErrors.at(0)}
                              onChange={(e) =>
                                field.handleChange(e.target.value)
                              }
                              containerClassName="md:col-span-4"
                              dataTestId="prequal-app-addressApartmentDetails-input"
                            />
                          );
                        }}
                      </form.Field>

                      <form.Field name="addressZipCode">
                        {(field) => {
                          return (
                            <TextInput
                              fieldName={field.name}
                              value={field.state.value}
                              labelText="ZIP Code"
                              placeholder="ZIP Code"
                              subtitleText="ZIP Code"
                              required
                              disabled={
                                isSubmitting ||
                                !values.acknowledgesInformationUnderstanding
                              }
                              error={field.state.meta.touchedErrors.at(0)}
                              onChange={(e) =>
                                field.handleChange(e.target.value)
                              }
                              containerClassName="md:col-span-3"
                              dataTestId="prequal-app-addressZipCode-input"
                            />
                          );
                        }}
                      </form.Field>

                      <form.Field name="addressCity">
                        {(field) => {
                          return (
                            <TextInput
                              fieldName={field.name}
                              value={field.state.value}
                              labelText="City"
                              placeholder="City"
                              subtitleText="City"
                              required
                              disabled={
                                isSubmitting ||
                                !values.acknowledgesInformationUnderstanding
                              }
                              error={field.state.meta.touchedErrors.at(0)}
                              onChange={(e) =>
                                field.handleChange(e.target.value)
                              }
                              containerClassName="md:col-span-2"
                              dataTestId="prequal-app-addressCity-input"
                            />
                          );
                        }}
                      </form.Field>

                      <form.Field name="addressState">
                        {(field) => {
                          return (
                            <DropDown
                              fieldName={field.name}
                              value={field.state.value}
                              options={stateOptions}
                              labelText="State"
                              placeholder="State"
                              subtitleText="State"
                              required
                              disabled={
                                isSubmitting ||
                                !values.acknowledgesInformationUnderstanding
                              }
                              error={field.state.meta.touchedErrors.at(0)}
                              onChange={(e) =>
                                field.handleChange(e.target.value)
                              }
                              containerClassName="md:col-span-3"
                              dataTestId="prequal-app-addressState-input"
                            />
                          );
                        }}
                      </form.Field>
                    </div>
                  </div>
                </div>

                <div className="flex w-full bg-primary p-6 justify-center">
                  <div className="flex flex-col items-start w-full max-w-screen-md space-y-6">
                    <div className="space-y-4">
                      <h2
                        className={classNames({
                          'text-inactive':
                            !values.acknowledgesInformationUnderstanding
                        })}
                      >
                        Our Commitment to Privacy
                      </h2>

                      <p
                        className={classNames({
                          'text-inactive':
                            !values.acknowledgesInformationUnderstanding
                        })}
                      >
                        All information stored in our database is secure and is
                        strictly confidential. Your personal and credit
                        information will only be used to fulfill your request
                        and in accordance with our Privacy Policy.
                      </p>

                      <form.Field
                        name="understandsCreditScoreAffection"
                        children={(field) => {
                          return (
                            <div
                              className="flex flex-row space-x-4 cursor-pointer"
                              onClick={() =>
                                field.handleChange(!field.state.value)
                              }
                            >
                              <Checkbox
                                inputId={field.name}
                                value={field.state.value}
                                onChange={() =>
                                  field.handleChange(!field.state.value)
                                }
                                disabled={
                                  isSubmitting ||
                                  !values.acknowledgesInformationUnderstanding
                                }
                                containerClassName="pt-1"
                                dataTestId="prequal-app-i-understand-checkbox"
                              />
                              <p
                                className={classNames({
                                  'text-inactive':
                                    !values.acknowledgesInformationUnderstanding
                                })}
                              >
                                I understand that this is not an application for
                                credit, and submission will NOT affect credit
                                score.
                              </p>
                            </div>
                          );
                        }}
                      />

                      <form.Field
                        name="acknowledgesPrivacyNotice"
                        children={(field) => {
                          return (
                            <div
                              className="flex flex-row space-x-4 cursor-pointer"
                              onClick={() =>
                                field.handleChange(!field.state.value)
                              }
                            >
                              <Checkbox
                                inputId={field.name}
                                value={field.state.value}
                                onChange={() =>
                                  field.handleChange(!field.state.value)
                                }
                                disabled={
                                  isSubmitting ||
                                  !values.acknowledgesInformationUnderstanding
                                }
                                containerClassName="pt-1"
                                dataTestId="prequal-app-privacy-notice-checkbox"
                              />

                              <p
                                className={classNames({
                                  'text-inactive':
                                    !values.acknowledgesInformationUnderstanding
                                })}
                              >
                                I hereby provide my consent to have my credit
                                file accessed for purposes of prequalifying for
                                a vehicle loan. This is a soft inquiry and will
                                not impact my credit score. I agree to the{' '}
                                <a
                                  href={`${config.rawBackendUrl}/document/${dealershipSlug}/privacy-notice.pdf`}
                                  target="_blank"
                                  className={classNames({
                                    'text-primary-brand cursor-pointer  hover:underline':
                                      values.acknowledgesInformationUnderstanding
                                  })}
                                >
                                  Privacy Notice
                                </a>
                                ,{' '}
                                <a
                                  href="https://files.mytdc.net/prequal-terms-and-conditions.pdf"
                                  target="_blank"
                                  className={classNames({
                                    'text-primary-brand cursor-pointer hover:underline':
                                      values.acknowledgesInformationUnderstanding
                                  })}
                                >
                                  Terms and Conditions
                                </a>{' '}
                                and I acknowledge I may be contacted by{' '}
                                {dealership?.name ?? '...'}. I understand that I
                                might not prequalify depending on the
                                prequalification criteria.
                              </p>
                            </div>
                          );
                        }}
                      />

                      <form.Field
                        name="providedInstructions"
                        children={(field) => {
                          return (
                            <div
                              className="flex flex-row space-x-4 cursor-pointer"
                              onClick={() =>
                                field.handleChange(!field.state.value)
                              }
                            >
                              <Checkbox
                                inputId={field.name}
                                value={field.state.value}
                                onChange={() =>
                                  field.handleChange(!field.state.value)
                                }
                                disabled={
                                  isSubmitting ||
                                  !values.acknowledgesInformationUnderstanding
                                }
                                containerClassName="pt-1"
                                dataTestId="prequal-app-provided-instruction-checkbox"
                              />

                              <p
                                className={classNames({
                                  'text-inactive':
                                    !values.acknowledgesInformationUnderstanding
                                })}
                              >
                                I hereby provide my consent to receive all
                                disclosures electronically via the TDC Buyer
                                Portal. I have been duly informed regarding the
                                procedures for accessing this portal, and I have
                                access to my email address. Additionally, I
                                acknowledge that I may request and receive
                                printed versions of these disclosures.
                              </p>
                            </div>
                          );
                        }}
                      />
                    </div>

                    <div className="flex flex-col space-y-2 w-full">
                      <div
                        className={classNames('font-bold', {
                          'text-inactive':
                            !values.acknowledgesInformationUnderstanding
                        })}
                      >
                        Signature
                      </div>

                      <Signature
                        dataTestIdOpenModal="prequal-app-signature-open-modal"
                        dataTestIdCanvas="prequal-app-signature-canvas"
                        dataTestIdSubmit="prequal-app-signature-submit"
                        existingSignature={signature}
                        onSignatureSubmit={(submittedSignature) =>
                          setSignature(submittedSignature)
                        }
                        disabled={
                          isSubmitting ||
                          !values.acknowledgesInformationUnderstanding
                        }
                      />
                    </div>

                    {error && (
                      <p className="text-negative">{error.toString()}</p>
                    )}

                    <div className="flex w-full justify-end">
                      <Button
                        type="submit"
                        disabled={isSubmitting || !canContinue}
                        loading={isSubmitting}
                        dataTestId="prequal-app-submit-button"
                      >
                        Submit
                      </Button>
                    </div>
                  </div>
                </div>
              </Fragment>
            );
          }}
        </form.Subscribe>
      </form>
    </form.Provider>
  );
};

export default PreQualForm;
