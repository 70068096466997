import { useQuery } from '@tanstack/react-query';
import classNames from 'classnames';
import { Fragment, useCallback, useRef, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { useLocation } from 'react-router-dom';
import Webcam from 'react-webcam';
import logoutAction from '~/actions/logoutAction';
import Button from '~/components/Button';
import { A4PageOverlay } from '~/components/Overlays';
import Spinner from '~/components/Spinner';
import CameraIcon from '~/components/icons/CameraIcon';
import ChevronRightIcon from '~/components/icons/ChevronRightIcon';
import DocumentsIcon from '~/components/icons/DocumentsIcon';
import { gqlMutationClient } from '~/lib/backend';
import { base64ToImageBlob } from '~/lib/files';
import { convertImageToPDF } from '~/lib/pdf';
import meQuery from '~/queries/meQuery';
import { Link, useNavigate, useParams } from '~/router';
import ConsumerDashboardHeader from '../../_components/ConsumerDashboardHeader';

function calculateOverlaySize(
  imageWidth: number,
  imageHeight: number,
  overlayHeightPercentage = 0.9
) {
  const aspectRatio = 21 / 29.7; // Width to height ratio of A4 Paper

  // Calculate the overlay height based on the desired height percentage
  const overlayHeight = imageHeight * overlayHeightPercentage;
  const overlayWidth = overlayHeight * aspectRatio;

  // Calculate the position of the overlay
  const overlayX = (imageWidth - overlayWidth) / 2;
  const overlayY = (imageHeight - overlayHeight) / 2;

  return { overlayWidth, overlayHeight, overlayX, overlayY };
}

const AddAdditionalDocumentTypes = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { transactionId, documentType, dealershipSlug } = useParams(
    '/dashboard/:dealershipSlug/:transactionId/addAdditionalDocuments/:documentType'
  );
  const documentName = location.state?.documentName;

  const [loggingOut, setLoggingOut] = useState(false);
  const handleLogout = async () => {
    setLoggingOut(true);
    await logoutAction();
  };
  const { isLoading: loadingUserData, data: meData } = useQuery(meQuery());
  const [isSubmitting, setIsSubmitting] = useState(false);

  // Camera States
  const [cameraLoaded, setCameraLoaded] = useState(false);
  const webcamRef = useRef<Webcam | null>(null);
  const [capture, setCapture] = useState<string | undefined>(undefined);

  const resetCapture = useCallback(() => {
    setCapture(undefined);
  }, [setCapture]);

  const handleRetake = useCallback(() => {
    resetCapture();
    setCameraLoaded(false);
  }, [resetCapture]);

  const handleCameraLoaded = () => {
    setCameraLoaded(true);
  };

  const takePicture = useCallback(async () => {
    if (webcamRef.current && !isSubmitting) {
      const screenshot = webcamRef.current.getScreenshot();
      if (screenshot) {
        const image = new Image();
        image.src = screenshot;

        try {
          await new Promise((resolve, reject) => {
            image.onload = resolve;
            image.onerror = reject;
          });

          // Will crop the image to A4 size
          const { overlayWidth, overlayHeight, overlayX, overlayY } =
            calculateOverlaySize(image.width, image.height);
          const canvas = document.createElement('canvas');
          canvas.width = overlayWidth;
          canvas.height = overlayHeight;
          const ctx = canvas.getContext('2d');

          if (!ctx) return;
          ctx.drawImage(
            image,
            overlayX,
            overlayY,
            overlayWidth,
            overlayHeight,
            0,
            0,
            overlayWidth,
            overlayHeight
          );

          const croppedImage = canvas.toDataURL('image/png');
          setCapture(croppedImage);
        } catch (error) {
          console.error('Error loading image for cropping:', error);
          setCapture(undefined);
        }
      } else {
        setCapture(undefined);
      }
    }
  }, [webcamRef, isSubmitting]);

  const handleUpload = async () => {
    if (!capture) return;

    setIsSubmitting(true);

    // Convert Capture to File and then to PDF
    const imageBase64 = capture?.split(',')[1];
    const imageBlob = base64ToImageBlob(imageBase64 ?? '', 'image/png');
    const imageFile = new File(
      [imageBlob],
      `additional_document_${documentType}`,
      {
        type: 'image/png'
      }
    );

    // Convert Image to PDF
    const fileToUpload = await convertImageToPDF(imageFile);
    const fileExt = fileToUpload.name.split('.').pop() ?? 'unknown';
    const mimeType = fileToUpload.type || 'application/octet-stream';

    const pictureUpload = await gqlMutationClient()({
      makeUpload: [
        {
          mimeType: mimeType,
          fileExt: fileExt
        },
        {
          __typename: true,
          '...on GraphQLError': { message: true },
          '...on MutationMakeUploadSuccess': {
            data: {
              fileId: true,
              uploadUrl: true
            }
          }
        }
      ]
    });

    const optimisticResp =
      pictureUpload.makeUpload?.__typename !== 'GraphQLError'
        ? pictureUpload.makeUpload?.data
        : undefined;

    if (!optimisticResp) return;

    if (optimisticResp.uploadUrl) {
      await fetch(optimisticResp.uploadUrl, {
        method: 'PUT',
        headers: {
          'Content-Type': mimeType,
          'Content-Length': fileToUpload.size.toString()
        },
        body: fileToUpload
      });
    }

    await gqlMutationClient()({
      uploadAdditionalDocuments: [
        {
          category: documentType,
          transactionId: transactionId,
          uploadedDocumentId: optimisticResp.fileId ?? 'should-never-happen',
          title: documentName
        },
        {
          __typename: true,
          '...on GraphQLError': { message: true },
          '...on MutationUploadAdditionalDocumentsSuccess': {
            data: {
              id: true
            }
          }
        }
      ]
    });

    setIsSubmitting(false);
    navigate('/dashboard/:dealershipSlug/:transactionId/gloveCompartment', {
      params: { transactionId, dealershipSlug }
    });
  };

  return (
    <div className="flex flex-col h-dvh w-full">
      <ConsumerDashboardHeader
        centerElement={
          <div className="flex flex-row space-x-2">
            <div className="relative">
              <DocumentsIcon className="size-4 md:size-5 icon-primary" />
            </div>

            <h2 className="hidden md:flex items-center">Documents & Forms</h2>

            <h3 className="flex md:hidden items-center">Documents & Forms</h3>
          </div>
        }
        rightElement={
          <Button
            variant="TEXT_ONLY"
            size="SMALL"
            onClick={handleLogout}
            loading={loggingOut}
            className="!p-0"
          >
            <span className="text-secondary">Logout</span>
          </Button>
        }
        showBorder
      />

      <div className="flex flex-col pt-4 space-y-6 w-full items-center overflow-y-scroll">
        <div className="w-full max-w-screen-md px-6 md:px-0">
          <Link
            to="/dashboard/:dealershipSlug/:transactionId/addAdditionalDocuments"
            params={{ transactionId, dealershipSlug }}
            className="flex w-6"
          >
            <div className="relative">
              <ChevronRightIcon className="w-6 -scale-x-100 icon-tertiary" />
            </div>
          </Link>
        </div>

        {loadingUserData && (
          <div className="flex w-full max-w-screen-md aspect-square justify-center items-center relative">
            <Spinner className="w-10 aspect-square" />
          </div>
        )}

        {!loadingUserData && (
          <div className="flex flex-col space-y-4 w-full max-w-screen-md">
            <div className="space-y-2 w-full px-6 md:px-0">
              <h2>Capture Documents</h2>

              <p>Please capture the document you wish to upload.</p>
            </div>

            <div className="flex flex-col w-full p-6 items-center">
              <div className="flex flex-row items-center justify-center">
                {(!cameraLoaded || loadingUserData) && !isSubmitting && (
                  <div className="flex absolute top-0 right-0 bottom-0 left-0 items-center justify-center">
                    <Spinner />
                  </div>
                )}

                {!capture && (
                  <div className="relative">
                    <Webcam
                      ref={webcamRef}
                      audio={false}
                      width={720}
                      height={480}
                      screenshotQuality={1}
                      imageSmoothing={false}
                      screenshotFormat="image/png"
                      className={classNames(
                        'rounded-lg max-w-full aspect-[3/2] object-cover',
                        {
                          'scale-x-[-1]': !isMobile
                        }
                      )}
                      videoConstraints={{
                        width: { min: 720, ideal: 1440 },
                        height: { min: 480, ideal: 960 },
                        aspectRatio: 1.5,
                        facingMode: { ideal: 'environment' }
                      }}
                      onUserMedia={handleCameraLoaded}
                    />
                    <A4PageOverlay heightPercentage={90} />
                  </div>
                )}

                {!!capture && (
                  <img
                    src={capture}
                    className="rounded-lg max-w-full object-cover"
                  />
                )}
              </div>
              <div className="flex flex-row gap-4 mt-7">
                {!capture && (
                  <div
                    className="flex w-20 justify-center aspect-square rounded-full border cursor-pointer icon-interactive border-interactive"
                    onClick={takePicture}
                  >
                    <div className="relative">
                      <CameraIcon className="w-10 aspect-square" filled />
                    </div>
                  </div>
                )}

                {!!capture && (
                  <Fragment>
                    <Button
                      size="DEFAULT"
                      variant="SECONDARY"
                      onClick={handleRetake}
                      disabled={!capture || isSubmitting}
                    >
                      Retake
                    </Button>

                    <Button
                      size="DEFAULT"
                      variant="PRIMARY"
                      onClick={handleUpload}
                      disabled={!capture || isSubmitting}
                    >
                      Accept and continue
                    </Button>
                  </Fragment>
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
export default AddAdditionalDocumentTypes;
