import { Dialog, Transition } from '@headlessui/react';
import { Fragment, useEffect, useState } from 'react';
import logoutAtion from '~/actions/logoutAction';

// Logout in 40 seconds without activity
const timeToLogout = 9999999 as const;
const showModalAfter = 9999999 as const;

export default function LogoutTimer() {
  const circumference = 30 * 2 * Math.PI;
  const [, setTimerStartedAt] = useState(new Date());
  const [timePassed, setTimePassed] = useState(0);
  const [percent, setPercent] = useState(0);

  const timerReset = () => {
    setTimerStartedAt(new Date());
  };

  // Sorry about using useEffect here, it should be discouraged. I invite to
  // Change the implementaiton.
  // Also, some proper anitmation would be nice!
  useEffect(() => {
    // These events consitute activity
    addEventListener('touchstart', timerReset);
    addEventListener('mousemove', timerReset);
    addEventListener('keydown', timerReset);

    const handler = setInterval(() => {
      // This was going to be complicated no matter what.
      // Because this closure works outside of the life cycle of the component
      // we can not just read the value directly. We need to drag it in.
      //
      // NOTE: Don't use awaut in this calle back as we can generate a
      // racecondition when we write back the `timerStartedAt`
      setTimerStartedAt((timerStartedAt) => {
        const left = (new Date().getTime() - timerStartedAt.getTime()) / 1000;
        setTimePassed(left);

        const percent = (100 * left) / timeToLogout;
        setPercent(percent);

        if (percent > 100) {
          logoutAtion().then(() => {
            clearInterval(handler);
          });
        }

        return timerStartedAt;
      });
    }, 1000);

    return () => {
      removeEventListener('mousemove', timerReset);
      removeEventListener('touchstart', timerReset);
      removeEventListener('keydown', timerReset);
      clearInterval(handler);
    };
  }, []);

  const open = showModalAfter < timePassed;

  return (
    <Transition appear show={open} as={Fragment}>
      <Dialog as="div" className="relative z-modal" onClose={() => {}}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black/25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                <Dialog.Title as="h3" className="font-bold mb-6 text-center">
                  Would you like to log out?
                </Dialog.Title>
                <div className="text-center flex flex-col space-y-6">
                  <p className="text-primary text-xs">
                    You are About to be Logged Out Due to Inactivity. Touch The
                    Screen To Avoid Logging Out.
                  </p>
                  <p className="text-secondary">
                    {Number(timeToLogout - timePassed).toFixed(0)} Seconds
                  </p>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>

    //  Old code for the spinner,keeping it here while we entirely settle on design
    // <div className="fixed flex w-20 h-20 items-center justify-center overflow-hidden rounded-full bottom-5 right-5">
    //   <svg className="w-90 h-20" width={100} height={100}>
    //     <circle
    //       className="text-gray-300"
    //       strokeWidth="5"
    //       stroke="currentColor"
    //       fill="transparent"
    //       r="30"
    //       cx="40"
    //       cy="40"
    //     />
    //     <circle
    //       className="text-blue-600"
    //       strokeWidth="5"
    //       strokeDasharray={circumference}
    //       strokeDashoffset={circumference - (percent / 100) * circumference}
    //       strokeLinecap="round"
    //       stroke="currentColor"
    //       fill="transparent"
    //       r="30"
    //       cx="40"
    //       cy="40"
    //     />
    //   </svg>
    //   <span className="text-xs text-secondary absolute top-5 w-[60px] left-2 text-center">
    //     Logout Timer
    //   </span>
    // </div>
  );
}
