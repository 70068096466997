import { useQuery } from '@tanstack/react-query';
import Header from '~/components/Header';
import SectionElementRow from '~/components/SectionElementRow';
import Spinner from '~/components/Spinner';
import ChevronRightIcon from '~/components/icons/ChevronRightIcon';
import DocumentIcon from '~/components/icons/DocumentIcon';
import IdIcon from '~/components/icons/IdIcon';
import PhoneIcon from '~/components/icons/PhoneIcon';
import { gqlQueryClient } from '~/lib/backend';
import { getHumanReadableFormAndDocumentType } from '~/lib/files';
import meQuery from '~/queries/meQuery';
import { useNavigate, useParams } from '~/router';

const GloveCompartmentPage = () => {
  const navigate = useNavigate();
  const { transactionId, dealershipSlug } = useParams(
    '/dashboard/:dealershipSlug/:transactionId/gloveCompartment'
  );
  const { data: userData, isLoading: loadingUserData } = useQuery(meQuery());
  const { data: formSubmissionsData, isLoading: loadingFormSubmissions } =
    useQuery({
      enabled: Boolean(userData?.me?.user?.id),
      queryKey: ['transaction', transactionId, 'customer', 'formSubmissions'],
      queryFn: async () =>
        gqlQueryClient({
          dealershipSlug
        })({
          customer: [
            {
              userId: userData?.me?.user?.id ?? 'no-user-id',
              transactionId
            },
            {
              formSubmissions: [
                {},
                {
                  edges: {
                    node: {
                      id: true,
                      form: {
                        displayTitle: true
                      },
                      type: true
                    }
                  }
                }
              ],
              documents: [
                {},
                {
                  edges: {
                    node: {
                      id: true,
                      title: true,
                      category: true
                    }
                  }
                }
              ]
            }
          ]
        })
    });
  const handleClose = () => {
    navigate('/dashboard/:dealershipSlug/:transactionId', {
      params: { transactionId, dealershipSlug }
    });
  };
  const handleOpenFormSubmission = (formSubmissionId: string) => {
    navigate(
      '/dashboard/:dealershipSlug/:transactionId/gloveCompartment/:formSubmissionId',
      {
        params: { transactionId, formSubmissionId, dealershipSlug }
      }
    );
  };
  const handleOpenDocument = (documentId: string) => {
    navigate(
      '/dashboard/:dealershipSlug/:transactionId/gloveCompartment/document/:documentId',
      {
        params: { transactionId, documentId, dealershipSlug }
      }
    );
  };

  const getFormSubmissionIcon = (roleEnum: string) => {
    switch (roleEnum) {
      case 'CONTACT_ACKNOWLEDGEMENT':
        return <PhoneIcon className="size-8" />;
      case 'IDENTITY_VERIFICATION':
        return <IdIcon className="size-8" />;
      default:
        return <DocumentIcon className="size-8" />;
    }
  };
  const getFormSubmissionIconColor = (roleEnum: string) => {
    switch (roleEnum) {
      case 'PRIVACY_POLICY':
        return '#333333';
      case 'CONTACT_ACKNOWLEDGEMENT':
        return '#46546B';
      case 'IDENTITY_VERIFICATION':
        return '#6297BA';
      default:
        return '#828282';
    }
  };

  return (
    <div className="flex flex-col h-dvh">
      <Header
        title="View Documents"
        leftElement={
          <button className=" text-primary-brand" onClick={handleClose}>
            Close
          </button>
        }
      />

      {(loadingUserData || loadingFormSubmissions) && (
        <div className="flex flex-1 items-center justify-center">
          <Spinner />
        </div>
      )}

      {!loadingUserData && !loadingFormSubmissions && (
        <div className="flex flex-col overflow-y-scroll p-8">
          <div className="flex flex-col space-y-0.5">
            {formSubmissionsData?.customer?.formSubmissions?.edges?.map(
              (edge) => {
                const formSubmission = edge.node;
                return (
                  <SectionElementRow
                    key={formSubmission?.id}
                    title={
                      formSubmission?.form?.displayTitle ??
                      (formSubmission?.type
                        ? getHumanReadableFormAndDocumentType(
                            formSubmission.type
                          )
                        : 'No form title')
                    }
                    onClick={() =>
                      handleOpenFormSubmission(
                        formSubmission?.id ?? 'no-form-submission-id'
                      )
                    }
                    iconLeft={
                      <div className="relative">
                        {getFormSubmissionIcon(
                          formSubmission?.type ?? 'no-form-submission-type'
                        )}
                      </div>
                    }
                    iconLeftColor={getFormSubmissionIconColor(
                      formSubmission?.type ?? 'no-form-submission-type'
                    )}
                    iconRight={
                      <div className="relative">
                        <ChevronRightIcon className="w-8 aspect-square  " />
                      </div>
                    }
                  />
                );
              }
            )}
            {formSubmissionsData?.customer?.documents?.edges?.map(
              ({ node: document }) => {
                return (
                  <SectionElementRow
                    key={document?.id}
                    title={document?.title ?? 'No form title'}
                    onClick={() =>
                      handleOpenDocument(document?.id ?? 'no-document-id')
                    }
                    iconLeft={
                      <div className="relative">
                        {getFormSubmissionIcon(
                          document?.category ?? 'no-document-categoyr'
                        )}
                      </div>
                    }
                    iconLeftColor={getFormSubmissionIconColor(
                      document?.category ?? 'no-document-category'
                    )}
                    iconRight={
                      <div className="relative">
                        <ChevronRightIcon className="w-8 aspect-square  " />
                      </div>
                    }
                  />
                );
              }
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default GloveCompartmentPage;
