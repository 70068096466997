import { FormApi, useForm } from '@tanstack/react-form';
import { parsePhoneNumber } from 'libphonenumber-js';
import omit from 'lodash/omit';
import { FC, Fragment, useState } from 'react';
import createEmploymentAndEmployerAction from '~/actions/employments/createEmploymentAndEmployerAction';
import deleteEmploymentAction from '~/actions/employments/deleteEmploymentAction';
import updateEmploymentAndEmployerAction from '~/actions/employments/updateEmploymentAndEmployerAction';
import Button from '~/components/Button';
import DropDown from '~/components/inputs/DropDown';
import NumberInput, {
  parseNumberInputValue
} from '~/components/inputs/NumberInput';
import PhoneNumberInput from '~/components/inputs/PhoneNumberInput';
import TextInput from '~/components/inputs/TextInput';
import employmentStatusOptions from '~/config/formSelectionOptions/employmentStatusOptions';
import incomeIntervalOptions from '~/config/formSelectionOptions/incomeIntervalOptions';
import stateOptions from '~/config/formSelectionOptions/stateOptions';
import { resetHardCreditQuery } from '~/queries/hardCreditQuery';
import { resetMeQuery } from '~/queries/meQuery';
import { EmploymentType } from '~/querySelectors/employment';

const EmploymentFormFields: FC<{
  form: FormApi<
    {
      employmentStatus: string;
      employerName: string;
      occupation: string;
      employerPhoneNumber: string;
      employerStreet: string;
      employerApartmentDetails: string;
      employerZipCode: string;
      employerCity: string;
      employerState: string;
      durationYears: string;
      durationMonths: string;
      incomeAmount: string;
      incomeInterval: string;
      otherIncomeSource: string;
      otherIncomeAmount: string;
      otherIncomeInterval: string;
    },
    unknown
  >;
  isSubmitting: boolean;
  isDeleting: boolean;
}> = ({ form, isSubmitting, isDeleting }) => {
  return (
    <div className="grid grid-cols-2 md:grid-cols-8 gap-4 w-full max-w-screen-md justify-end">
      <form.Field name="employmentStatus">
        {(field) => {
          return (
            <DropDown
              fieldName={field.name}
              value={field.state.value}
              options={employmentStatusOptions}
              labelText="Employment Status"
              subtitleText="Employment Status"
              placeholder="Employment Status"
              required
              disabled={isSubmitting || isDeleting}
              error={field.state.meta.touchedErrors.at(0)}
              onChange={(e) => field.handleChange(e.target.value)}
              containerClassName="col-span-2 md:col-span-8"
            />
          );
        }}
      </form.Field>

      <form.Subscribe
        selector={(state) => [state.values]}
        children={([values]) => {
          return (
            <Fragment>
              {['EMPLOYED', 'SELF_EMPLOYED'].includes(
                values.employmentStatus ?? ''
              ) && (
                <Fragment>
                  <form.Field name="employerName">
                    {(field) => {
                      return (
                        <TextInput
                          fieldName={field.name}
                          value={field.state.value}
                          labelText="Employer Name"
                          subtitleText="Employer Name"
                          placeholder="Employer Name"
                          required
                          disabled={isSubmitting || isDeleting}
                          error={field.state.meta.touchedErrors.at(0)}
                          onChange={(e) => field.handleChange(e.target.value)}
                          containerClassName="col-span-2 md:col-span-8"
                        />
                      );
                    }}
                  </form.Field>

                  <form.Field name="employerStreet">
                    {(field) => {
                      return (
                        <TextInput
                          fieldName={field.name}
                          value={field.state.value}
                          labelText="Employer Address Street"
                          subtitleText="Employer Address Street"
                          placeholder="Street"
                          required
                          disabled={isSubmitting || isDeleting}
                          error={field.state.meta.touchedErrors.at(0)}
                          onChange={(e) => field.handleChange(e.target.value)}
                          containerClassName="md:col-span-4"
                        />
                      );
                    }}
                  </form.Field>

                  <form.Field name="employerApartmentDetails">
                    {(field) => {
                      return (
                        <TextInput
                          fieldName={field.name}
                          value={field.state.value}
                          labelText="Employer Address Apartment Details"
                          subtitleText="Employer Address Apartment Details"
                          placeholder="Suite, Apt #"
                          disabled={isSubmitting || isDeleting}
                          error={field.state.meta.touchedErrors.at(0)}
                          onChange={(e) => field.handleChange(e.target.value)}
                          containerClassName="md:col-span-4"
                        />
                      );
                    }}
                  </form.Field>

                  <form.Field name="employerZipCode">
                    {(field) => {
                      return (
                        <TextInput
                          fieldName={field.name}
                          value={field.state.value}
                          labelText="Employer Address Zip Code"
                          subtitleText="Employer Address Zip Code"
                          placeholder="Zip Code"
                          required
                          disabled={isSubmitting || isDeleting}
                          error={field.state.meta.touchedErrors.at(0)}
                          onChange={(e) => field.handleChange(e.target.value)}
                          containerClassName="md:col-span-3"
                        />
                      );
                    }}
                  </form.Field>

                  <form.Field name="employerCity">
                    {(field) => {
                      return (
                        <TextInput
                          fieldName={field.name}
                          value={field.state.value}
                          labelText="Employer Address City"
                          subtitleText="Employer Address City"
                          placeholder="City"
                          required
                          disabled={isSubmitting || isDeleting}
                          error={field.state.meta.touchedErrors.at(0)}
                          onChange={(e) => field.handleChange(e.target.value)}
                          containerClassName="md:col-span-2"
                        />
                      );
                    }}
                  </form.Field>

                  <form.Field name="employerState">
                    {(field) => {
                      return (
                        <DropDown
                          fieldName={field.name}
                          value={field.state.value}
                          options={stateOptions}
                          labelText="Employer Address State"
                          placeholder="State"
                          subtitleText="Employer Address State"
                          required
                          disabled={isSubmitting || isDeleting}
                          error={field.state.meta.touchedErrors.at(0)}
                          onChange={(e) => field.handleChange(e.target.value)}
                          containerClassName="md:col-span-3"
                        />
                      );
                    }}
                  </form.Field>

                  {/** The naming of the field changed in the designs. So label/subtitle texts are different than the variable name */}
                  <form.Field name="occupation">
                    {(field) => {
                      return (
                        <TextInput
                          fieldName={field.name}
                          value={field.state.value}
                          labelText="Role"
                          subtitleText="Role"
                          placeholder="Role"
                          required
                          disabled={isSubmitting || isDeleting}
                          error={field.state.meta.touchedErrors.at(0)}
                          onChange={(e) => field.handleChange(e.target.value)}
                          containerClassName="md:col-span-8"
                        />
                      );
                    }}
                  </form.Field>

                  <form.Field name="employerPhoneNumber">
                    {(field) => {
                      return (
                        <PhoneNumberInput
                          fieldName={field.name}
                          value={field.state.value}
                          subtitleText="Employer Phone Number"
                          placeholder="Employer Phone Number"
                          onChange={(value) =>
                            field.handleChange(value?.toString() ?? '')
                          }
                          onBlur={field.handleBlur}
                          disabled={isSubmitting || isDeleting}
                          required
                          containerClassName="col-span-2 md:col-span-8"
                        />
                      );
                    }}
                  </form.Field>
                </Fragment>
              )}

              <form.Field name="durationYears">
                {(field) => {
                  return (
                    <NumberInput
                      fieldName={field.name}
                      value={field.state.value}
                      labelText="Duration in Years"
                      subtitleText="How many years have you been working at this job?"
                      placeholder="Duration (Yrs.)"
                      required
                      suffix=" Yrs."
                      disabled={isSubmitting || isDeleting}
                      onChange={(e) => field.handleChange(e.target.value)}
                      containerClassName="md:col-span-4"
                    />
                  );
                }}
              </form.Field>

              <form.Field name="durationMonths">
                {(field) => {
                  return (
                    <NumberInput
                      fieldName={field.name}
                      value={field.state.value}
                      labelText="Duration in Months"
                      subtitleText="How many months have you been working at this job?"
                      placeholder="Duration (Mos.)"
                      suffix=" Mos."
                      disabled={isSubmitting || isDeleting}
                      onChange={(e) => field.handleChange(e.target.value)}
                      containerClassName="md:col-span-4"
                    />
                  );
                }}
              </form.Field>

              <form.Field name="incomeAmount">
                {(field) => {
                  return (
                    <NumberInput
                      fieldName={field.name}
                      value={field.state.value}
                      subtitleText="Gross Income"
                      placeholder="Gross Income"
                      disabled={isSubmitting || isDeleting}
                      prefix="$"
                      required
                      onChange={(e) => field.handleChange(e.target.value)}
                      containerClassName="md:col-span-4"
                    />
                  );
                }}
              </form.Field>

              <form.Field name="incomeInterval">
                {(field) => {
                  return (
                    <DropDown
                      fieldName={field.name}
                      value={field.state.value}
                      options={incomeIntervalOptions}
                      labelText="Per Time Period"
                      subtitleText="Per Time Period"
                      placeholder="Per Time Period"
                      required
                      disabled={isSubmitting || isDeleting}
                      error={field.state.meta.touchedErrors.at(0)}
                      onChange={(e) => field.handleChange(e.target.value)}
                      containerClassName="md:col-span-4"
                    />
                  );
                }}
              </form.Field>

              <form.Field name="otherIncomeSource">
                {(field) => {
                  return (
                    <TextInput
                      fieldName={field.name}
                      value={field.state.value}
                      labelText="Other Income Source"
                      subtitleText="Other Income Source"
                      placeholder="Other Income Source"
                      disabled={isSubmitting || isDeleting}
                      error={field.state.meta.touchedErrors.at(0)}
                      onChange={(e) => field.handleChange(e.target.value)}
                      containerClassName="md:col-span-8"
                    />
                  );
                }}
              </form.Field>

              <form.Field name="otherIncomeAmount">
                {(field) => {
                  return (
                    <NumberInput
                      fieldName={field.name}
                      value={field.state.value}
                      subtitleText="Gross Income"
                      placeholder="Gross Income"
                      disabled={isSubmitting || isDeleting}
                      prefix="$"
                      onChange={(e) => field.handleChange(e.target.value)}
                      containerClassName="md:col-span-4"
                    />
                  );
                }}
              </form.Field>

              <form.Field name="otherIncomeInterval">
                {(field) => {
                  return (
                    <DropDown
                      fieldName={field.name}
                      value={field.state.value}
                      options={incomeIntervalOptions}
                      labelText="Per Time Period"
                      subtitleText="Per Time Period"
                      placeholder="Per Time Period"
                      disabled={isSubmitting || isDeleting}
                      error={field.state.meta.touchedErrors.at(0)}
                      onChange={(e) => field.handleChange(e.target.value)}
                      containerClassName="md:col-span-4"
                    />
                  );
                }}
              </form.Field>
            </Fragment>
          );
        }}
      />
    </div>
  );
};

const EmploymentForm: FC<{
  transactionId: string;
  employments: EmploymentType[];
  hasMoreThanTwoYearsHistory: boolean;
}> = ({ transactionId, employments, hasMoreThanTwoYearsHistory }) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [isAdding, setIsAdding] = useState(false);
  const [editingPos, setEditingPos] = useState<number | undefined>(undefined);
  const handleEdit = (pos?: number) => {
    setIsAdding(false);
    setEditingPos(pos);
  };
  const handleDiscard = () => {
    setIsAdding(false);
    setEditingPos(undefined);
  };
  const handleAdd = () => {
    setEditingPos(undefined);
    setIsAdding(true);
  };
  const editedEmployment = employments.find(
    (e) => e.timelinePosition === editingPos
  );
  const form = useForm({
    defaultValues: {
      employmentStatus: editedEmployment?.employmentStatus ?? '',
      employerName: editedEmployment?.employerName ?? '',
      occupation: editedEmployment?.occupation ?? '',
      employerPhoneNumber: editedEmployment?.employerPhoneNumber ?? '',
      employerStreet: editedEmployment?.employerAddress?.street ?? '',
      employerApartmentDetails:
        editedEmployment?.employerAddress?.apartmentDetails ?? '',
      employerZipCode: editedEmployment?.employerAddress?.zipCode ?? '',
      employerCity: editedEmployment?.employerAddress?.city ?? '',
      employerState: editedEmployment?.employerAddress?.state ?? '',
      durationYears: editedEmployment?.durationYears?.toString() ?? '',
      durationMonths: editedEmployment?.durationMonths?.toString() ?? '',
      incomeAmount: editedEmployment?.incomeAmount?.toString() ?? '',
      incomeInterval: editedEmployment?.incomeInterval ?? '',
      otherIncomeSource: editedEmployment?.otherIncomeSource ?? '',
      otherIncomeAmount: editedEmployment?.otherIncomeAmount?.toString() ?? '',
      otherIncomeInterval: editedEmployment?.otherIncomeInterval ?? ''
    },
    onSubmit: async (values) => {
      setIsSubmitting(true);

      const employmentValues = omit(
        {
          ...values,
          employerPhoneNumber: values.employerPhoneNumber.length // We have to check whether the phone number has been set because an empty string will fail the phone number validation
            ? values.employerPhoneNumber
            : undefined,
          durationYears: parseNumberInputValue(values.durationYears) ?? 0,
          durationMonths: parseNumberInputValue(values.durationMonths) ?? 0,
          incomeAmount: parseNumberInputValue(values.incomeAmount) ?? 0,
          otherIncomeAmount: parseNumberInputValue(values.otherIncomeAmount),
          otherIncomeInterval: values.otherIncomeInterval.length // otherIncomeInterval is optional, so we have to pass undefined if the value is an empty string
            ? values.otherIncomeInterval
            : undefined
        },
        [
          // We have to omit these values as they are not valid input arguments
          'employerStreet',
          'employerApartmentDetails',
          'employerZipCode',
          'employerState',
          'employerCity'
        ]
      );

      const employerAddress = {
        street: values.employerStreet,
        apartmentDetails: values.employerApartmentDetails,
        zipCode: values.employerZipCode,
        state: values.employerState,
        city: values.employerCity
      };

      // Check if we are editing an existing employment
      if (editedEmployment) {
        await updateEmploymentAndEmployerAction(
          transactionId,
          editedEmployment.id ?? 'no-employment-id',
          employmentValues,
          employerAddress
        );

        await Promise.all([
          await resetMeQuery(),
          await resetHardCreditQuery(transactionId)
        ]);

        setEditingPos(undefined);
        form.reset();
      } else {
        // We are creating a new employment
        await createEmploymentAndEmployerAction(
          transactionId,
          {
            ...employmentValues,
            timelinePosition: employments.length
          },
          employerAddress
        );

        await Promise.all([
          await resetMeQuery(),
          await resetHardCreditQuery(transactionId)
        ]);

        setEditingPos(undefined);
        setIsAdding(false);
        form.reset();
      }

      setIsSubmitting(false);
    }
  });
  const handleDelete = async (employmentId: string) => {
    setIsDeleting(true);

    await deleteEmploymentAction(employmentId);

    await Promise.all([
      await resetMeQuery(),
      await resetHardCreditQuery(transactionId)
    ]);

    setEditingPos(undefined);
    form.reset();

    setIsDeleting(false);
  };

  return (
    <div className="flex flex-col items-center w-full space-y-4">
      <div className="flex w-full px-6 justify-center">
        <div className="flex flex-col space-y-4 w-full max-w-screen-md bg-primary">
          <h1 className="w-full max-w-screen-md">Employment Information</h1>

          {!isAdding && !hasMoreThanTwoYearsHistory && (
            <Button size="SMALL" variant="TEXT_ONLY" onClick={handleAdd}>
              +Add
            </Button>
          )}
        </div>
      </div>

      {employments.map((e) => {
        if (editingPos === e.timelinePosition) {
          return (
            <form.Provider key={e.id}>
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  void form.handleSubmit();
                }}
                className="flex flex-col w-full space-y-4 bg-secondary border-y border-secondary items-center p-6"
              >
                <div className="flex flex-row justify-between w-full max-w-screen-md items-center">
                  <h3>
                    {e.timelinePosition === 0 &&
                      'Current Employment Information'}
                    {e.timelinePosition === 1 &&
                      'Previous Employment Information'}
                    {e.timelinePosition === 2 &&
                      '2nd Previous Employment Information'}
                    {e.timelinePosition === 3 &&
                      '3rd Previous Employment Information'}
                    {!!e.timelinePosition &&
                      e.timelinePosition > 3 &&
                      `${e.timelinePosition}th Previous Employment Information`}
                  </h3>

                  <div className="flex flex-row items-center">
                    <form.Subscribe
                      selector={(state) => [state.values]}
                      children={([values]) => {
                        let canContinue =
                          !!values.employmentStatus &&
                          !!values.incomeAmount &&
                          !!values.incomeInterval &&
                          values.durationYears !== '' &&
                          (parseInt(values.durationYears.toString()) > 0 ||
                            parseInt(values.durationMonths.toString()) > 0);

                        if (
                          canContinue &&
                          ['EMPLOYED', 'SELF_EMPLOYED'].includes(
                            values.employmentStatus ?? ''
                          )
                        ) {
                          canContinue =
                            !!values.employerName &&
                            !!values.occupation &&
                            !!values.employerPhoneNumber &&
                            !!values.employerStreet &&
                            !!values.employerZipCode &&
                            !!values.employerCity &&
                            !!values.employerState;
                        }

                        return (
                          <Button
                            disabled={!canContinue || isDeleting}
                            loading={isSubmitting}
                            type="submit"
                            size="SMALL"
                            variant="TEXT_ONLY"
                          >
                            Save
                          </Button>
                        );
                      }}
                    />

                    <span className="icon-tertiary text-sm">|</span>

                    <Button
                      size="SMALL"
                      variant="TEXT_ONLY"
                      onClick={() => handleDiscard()}
                    >
                      Discard
                    </Button>
                  </div>
                </div>

                <EmploymentFormFields
                  form={form}
                  isSubmitting={isSubmitting}
                  isDeleting={isDeleting}
                />
              </form>
            </form.Provider>
          );
        }

        return (
          <div key={e.id} className="flex w-full justify-center px-6">
            <div className="flex flex-col space-y-6 p-4 rounded-lg shadow-border w-full max-w-screen-md px-6">
              <div className="flex flex-row w-full justify-between items-center">
                {e.timelinePosition === 0 && <h3>Current Employment</h3>}
                {e.timelinePosition === 1 && <h3>Previous Employment</h3>}
                {e.timelinePosition === 2 && <h3>2nd Previous Employment</h3>}
                {e.timelinePosition === 3 && <h3>3rd Previous Employment</h3>}
                {!!e.timelinePosition && e.timelinePosition > 3 && (
                  <h3>${e.timelinePosition}th Previous Employment</h3>
                )}

                <div className="flex flex-row items-center">
                  <Button
                    size="SMALL"
                    variant="TEXT_ONLY"
                    onClick={() => handleEdit(e.timelinePosition)}
                  >
                    Edit
                  </Button>

                  <span className="icon-tertiary text-sm">|</span>

                  <Button
                    size="SMALL"
                    variant="TEXT_ONLY"
                    onClick={() => handleDelete(e.id ?? 'no-employement-id')}
                    loading={isDeleting}
                    disabled={employments.length < 2}
                  >
                    Delete
                  </Button>
                </div>
              </div>

              <div className="grid grid-cols-2 md:grid-cols-4 gap-4">
                {e.employerName && (
                  <div className="space-y-2">
                    <p className="text-body-2 text-tertiary">Employer Name:</p>

                    <p className="text-secondary">{e.employerName}</p>
                  </div>
                )}

                {(e.occupation || e.employerPhoneNumber) && (
                  <Fragment>
                    <div className="space-y-2">
                      <p className="text-body-2 text-tertiary">Occupation:</p>

                      <p className="text-secondary">{e.occupation}</p>
                    </div>

                    <div className="space-y-2">
                      <p className="text-body-2 text-tertiary">
                        Employer Phone Number:
                      </p>

                      <p className="text-secondary">
                        {e.employerPhoneNumber
                          ? parsePhoneNumber(e.employerPhoneNumber).format(
                              parsePhoneNumber(e.employerPhoneNumber)
                                ?.country === 'US'
                                ? 'NATIONAL'
                                : 'INTERNATIONAL'
                            )
                          : ''}
                      </p>
                    </div>
                  </Fragment>
                )}

                {e.employerAddress && (
                  <div className="space-y-2">
                    <p className="text-body-2 text-tertiary">
                      Employer Address:
                    </p>

                    <p className="text-secondary">
                      {e.employerAddress.fullAddress}
                    </p>
                  </div>
                )}

                <div className="space-y-2">
                  <p className="text-body-2 text-tertiary">
                    Employment Status:
                  </p>

                  <p className="text-secondary">
                    {
                      employmentStatusOptions.find(
                        (o) => o.value === e.employmentStatus
                      )?.label
                    }
                  </p>
                </div>

                <div className="space-y-2">
                  <p className="text-body-2 text-tertiary">
                    Employment Duration:
                  </p>

                  <p className="text-secondary">
                    {e.durationYears} Yrs {e.durationMonths} Mos
                  </p>
                </div>

                <div className="space-y-2">
                  <p className="text-body-2 text-tertiary">Salary:</p>

                  <p className="text-secondary">
                    ${e.incomeAmount?.toLocaleString()}
                  </p>
                </div>

                <div className="space-y-2">
                  <p className="text-body-2 text-tertiary">Per Time Period:</p>

                  <p className="text-secondary">
                    {
                      incomeIntervalOptions.find(
                        (o) => o.value === e.incomeInterval
                      )?.label
                    }
                  </p>
                </div>

                {e.otherIncomeSource && (
                  <div className="space-y-2">
                    <p className="text-body-2 text-tertiary">
                      Other Income Source:
                    </p>

                    <p className="text-secondary">{e.otherIncomeSource}</p>
                  </div>
                )}

                {e.otherIncomeAmount && (
                  <div className="space-y-2">
                    <p className="text-body-2 text-tertiary">Income Amount:</p>

                    <p className="text-secondary">
                      ${e.otherIncomeAmount.toLocaleString()}
                    </p>
                  </div>
                )}

                {e.otherIncomeInterval && (
                  <div className="space-y-2">
                    <p className="text-body-2 text-tertiary">
                      Per Time Period:
                    </p>

                    <p className="text-secondary">
                      {
                        incomeIntervalOptions.find(
                          (o) => o.value === e.otherIncomeInterval
                        )?.label
                      }
                    </p>
                  </div>
                )}
              </div>
            </div>
          </div>
        );
      })}

      {isAdding && !editedEmployment && (
        <form.Provider>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              e.stopPropagation();
              void form.handleSubmit();
            }}
            className="flex flex-col w-full space-y-4 bg-secondary border-y border-secondary items-center p-6"
          >
            <div className="flex flex-row justify-between w-full max-w-screen-md items-center">
              <h3>
                {employments.length === 0 && 'Current Employment Information'}
                {employments.length === 1 && 'Previous Employment Information'}
                {employments.length === 2 &&
                  '2nd Previous Employment Information'}
                {employments.length === 3 &&
                  '3rd Previous Employment Information'}
                {employments.length > 3 &&
                  `${employments.length}th Previous Employment Information`}
              </h3>

              <div className="flex flex-row items-center">
                <form.Subscribe
                  selector={(state) => [state.values]}
                  children={([values]) => {
                    let canContinue =
                      !!values.employmentStatus &&
                      !!values.incomeAmount &&
                      !!values.incomeInterval &&
                      values.durationYears !== '' &&
                      (parseInt(values.durationYears.toString()) > 0 ||
                        parseInt(values.durationMonths.toString()) > 0);

                    if (
                      canContinue &&
                      ['EMPLOYED', 'SELF_EMPLOYED'].includes(
                        values.employmentStatus ?? ''
                      )
                    ) {
                      canContinue =
                        !!values.employerName &&
                        !!values.occupation &&
                        !!values.employerPhoneNumber &&
                        !!values.employerStreet &&
                        !!values.employerZipCode &&
                        !!values.employerCity &&
                        !!values.employerState;
                    }

                    return (
                      <Button
                        disabled={!canContinue || isDeleting}
                        loading={isSubmitting}
                        type="submit"
                        size="SMALL"
                        variant="TEXT_ONLY"
                      >
                        Save
                      </Button>
                    );
                  }}
                />

                <span className="icon-tertiary text-sm">|</span>

                <Button
                  size="SMALL"
                  variant="TEXT_ONLY"
                  onClick={() => handleDiscard()}
                >
                  Discard
                </Button>
              </div>
            </div>

            <EmploymentFormFields
              form={form}
              isSubmitting={isSubmitting}
              isDeleting={isDeleting}
            />
          </form>
        </form.Provider>
      )}
    </div>
  );
};

export default EmploymentForm;
