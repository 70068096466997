import { useQuery } from '@tanstack/react-query';
import { FC } from 'react';
import Button from '~/components/Button';
import ProfileCircleIcon from '~/components/icons/ProfileCircleIcon';
import customerQuery from '~/queries/customerQuery';
import { TransactionQueryType } from '~/queries/transactionQuery';
import { useParams } from '~/router';

const PersonalInformationSection: FC<{
  isBuyer: boolean;
  name: string;
  phoneNumber: string;
  email: string;
  onEditPersonalInformation: () => void;
  transactionRole: 'BUYER' | 'CO_BUYER';
  transaction: TransactionQueryType['transaction'];
}> = ({
  isBuyer,
  name,
  phoneNumber,
  email,
  onEditPersonalInformation,
  transactionRole,
  transaction
}) => {
  // This is a section component, so we can do this
  const { transactionId, dealershipSlug } = useParams(
    '/dashboard/:dealershipSlug/:transactionId'
  );

  const { data: customerData } = useQuery(
    customerQuery(
      transactionId,
      transactionRole === 'CO_BUYER'
        ? transaction?.coBuyerId
        : transaction?.buyerId,
      dealershipSlug
    )
  );
  const customer = customerData?.customer;

  const residentialAddress = customer?.residentialAddresses?.edges?.find(
    (a) => a.node?.timelinePosition === 0
  )?.node?.fullAddress;

  const vehicleRegistrationAddress = customer?.wantsRegistrationAtSameAddress
    ? (residentialAddress ?? '-')
    : (customer?.vehicleRegistrationAddress?.fullAddress ?? '-');
  return (
    <div className="flex justify-center bg-secondary py-4 px-6">
      <div className="bg-primary w-full max-w-screen-lg p-4 rounded-lg shadow-border space-y-4">
        <div className="flex flex-row justify-between">
          <div className="flex flex-row space-x-2">
            <ProfileCircleIcon className="size-6 icon-quaternary" />

            <h2>Personal Information</h2>
          </div>

          <Button
            variant="TEXT_ONLY"
            onClick={onEditPersonalInformation}
            className="pt-0 pr-0"
          >
            Edit
          </Button>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-4 gap-4">
          <div className="grid md:grid-cols-2 md:col-span-2 gap-4">
            <div className="flex flex-col space-y-1">
              <p className="text-body-3 font-bold uppercase text-tertiary">
                Name
              </p>

              <p>{name}</p>
            </div>

            <div className="flex flex-col space-y-1">
              <p className="text-body-3 font-bold uppercase text-tertiary">
                Phone
              </p>

              <p>{phoneNumber}</p>
            </div>

            <div className="flex flex-col space-y-1 max-w-full overflow-hidden">
              <p className="text-body-3 font-bold uppercase text-tertiary">
                Email
              </p>

              <p className="truncate">{email}</p>
            </div>
          </div>

          <div>
            <p className="text-body-3 font-bold uppercase text-tertiary">
              Personal Address
            </p>

            <p>{residentialAddress}</p>
          </div>

          {isBuyer && (
            <div>
              <p className="text-body-3 font-bold uppercase text-tertiary">
                Register To Address
              </p>

              <p>{vehicleRegistrationAddress}</p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default PersonalInformationSection;
