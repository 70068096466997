import { FormApi, useForm } from '@tanstack/react-form';
import { useQuery } from '@tanstack/react-query';
import { FC, Fragment, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import createAddressAction from '~/actions/addresses/createAddressAction';
import deleteAddressAction from '~/actions/addresses/deleteAddressAction';
import updateAddressAction from '~/actions/addresses/updateAddressAction';
import Button from '~/components/Button';
import Header from '~/components/Header';
import ChevronRightIcon from '~/components/icons/ChevronRightIcon';
import DropDown from '~/components/inputs/DropDown';
import NumberInput, {
  parseNumberInputValue
} from '~/components/inputs/NumberInput';
import TextInput from '~/components/inputs/TextInput';
import Spinner from '~/components/Spinner';
import housingStatusOptions from '~/config/formSelectionOptions/housingStatusOptions';
import stateOptions from '~/config/formSelectionOptions/stateOptions';
import customerQuery from '~/queries/customerQuery';
import { resetHardCreditQuery } from '~/queries/hardCreditQuery';
import meQuery, { resetMeQuery } from '~/queries/meQuery';
import { AddressType } from '~/querySelectors/address';
import { Link, useNavigate, useParams } from '~/router';

const HardCreditApplicationAddressInfoForm: FC<{
  addresses?: AddressType[];
  isDeleting: boolean;
  setIsDeleting: React.Dispatch<React.SetStateAction<boolean>>;
  isSubmitting: boolean;
  setIsSubmitting: React.Dispatch<React.SetStateAction<boolean>>;
}> = ({
  isDeleting,
  setIsDeleting,
  isSubmitting,
  setIsSubmitting,
  addresses
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { transactionId, hardCreditApplicationId, dealershipSlug } = useParams(
    '/dashboard/:dealershipSlug/:transactionId/hardCredit/:hardCreditApplicationId/addressInfo'
  );
  const [timelinePosition, setTimelinePosition] = useState(
    addresses?.find((a) => a.timelinePosition === 0 && !a.housingStatus)
      ? 0
      : (addresses?.length ?? 0)
  );
  const [isEditing, setIsEditing] = useState(false);

  const currentAddress = addresses?.find(
    (a) => a.timelinePosition === timelinePosition
  );
  const moreThanTwoYears =
    (addresses
      ?.map((a) => (a.durationYears ?? 0) + (a.durationMonths ?? 0) / 12)
      .reduce((total, duration) => duration + total, 0) ?? 0) >= 2;

  const handleContinue = () => {
    navigate(
      '/dashboard/:dealershipSlug/:transactionId/hardCredit/:hardCreditApplicationId/employmentInfo',
      {
        params: {
          dealershipSlug,
          transactionId,
          hardCreditApplicationId
        }
      }
    );
  };
  const handleSubmit = useCallback(
    async (values: any, form: FormApi<any, unknown>) => {
      setIsSubmitting(true);

      if (currentAddress) {
        await updateAddressAction(currentAddress.id ?? 'no-address-id', {
          ...values,
          monthlyPayment: parseNumberInputValue(values['monthlyPayment']),
          durationYears: parseInt(values['durationYears']),
          durationMonths: values['durationMonths']
            ? parseInt(values['durationMonths'])
            : 0
        });

        setTimelinePosition(addresses?.length ?? 0);
      } else {
        await createAddressAction(
          {
            ...values,
            monthlyPayment: parseNumberInputValue(values['monthlyPayment']),
            durationYears: parseNumberInputValue(values['durationYears']),
            durationMonths: values['durationMonths']
              ? parseNumberInputValue(values['durationMonths'])
              : 0,
            timelinePosition
          },
          transactionId
        );

        setTimelinePosition((addresses?.length ?? 0) + 1);
      }

      await Promise.all([
        await resetMeQuery(),
        await resetHardCreditQuery(transactionId)
      ]);

      form.reset();
      setIsSubmitting(false);
      setIsEditing(false);
    },
    [
      timelinePosition,
      currentAddress,
      transactionId,
      setIsSubmitting,
      setTimelinePosition,
      addresses
    ]
  );

  //
  const form: FormApi<any, unknown> = useForm({
    defaultValues: {
      street: currentAddress?.street ?? '',
      apartmentDetails: currentAddress?.apartmentDetails ?? '',
      zipCode: currentAddress?.zipCode ?? '',
      city: currentAddress?.city ?? '',
      state: currentAddress?.state?.toLocaleLowerCase() ?? '',
      housingStatus: currentAddress?.housingStatus ?? '',
      monthlyPayment: currentAddress?.monthlyPayment ?? '',
      durationYears: currentAddress?.durationYears ?? '',
      durationMonths: currentAddress?.durationMonths ?? ''
    },
    onSubmit: handleSubmit
  });
  //
  const handleEdit = (pos: number) => {
    const address = addresses?.find((a) => a.timelinePosition === pos);

    form.setFieldValue('street', address?.street);
    form.setFieldValue('apartmentDetails', address?.apartmentDetails);
    form.setFieldValue('zipCode', address?.zipCode);
    form.setFieldValue('city', address?.city);
    form.setFieldValue('state', address?.state);
    form.setFieldValue('housingStatus', address?.housingStatus);
    form.setFieldValue('monthlyPayment', address?.monthlyPayment);
    form.setFieldValue('durationYears', address?.durationYears);
    form.setFieldValue('durationMonths', address?.durationMonths);
    setTimelinePosition(pos);
    setIsEditing(true);
  };
  const handleDelete = async (addressId: string) => {
    setIsDeleting(true);

    await deleteAddressAction(addressId);

    setTimelinePosition(
      addresses?.filter((a) => a.id !== addressId).length ?? 0
    );

    await Promise.all([
      await resetMeQuery(),
      await resetHardCreditQuery(transactionId)
    ]);

    setIsEditing(false);
    setIsDeleting(false);
    form.reset();
  };

  const renderAddress = (pos: number) => {
    const address = addresses?.find((a) => a.timelinePosition === pos);

    // Check whether the address information has been filled out completely.
    // During the onboarding, a residential address is given in some cases.
    // But in the onboarding, the user isn't asked about the housing status.
    // Here, we only want to render addresses with all the data.
    // Since housing status is part of the required fields, we can check based
    // on that field and don't render the address if it doesn't have a value for it.
    if (!address?.housingStatus) {
      return undefined;
    }

    return (
      <div
        key={pos}
        className="flex flex-col space-y-4 w-full rounded-lg overflow-hidden shadow-border p-4"
      >
        <div className="flex flex-col-reverse md:flex-row md:justify-between gap-2">
          <h2>
            {pos === 0 && 'Current Address and Housing Status'}
            {pos === 1 && 'Previous Address and Housing Status'}
            {pos === 2 && '2nd Previous Address and Housing Status'}
            {pos === 3 && '3rd Previous Address and Housing Status'}
            {pos > 3 && `${pos}th Previous Address and Housing Status`}
          </h2>

          <div className="flex flex-row items-center justify-end">
            <Button
              size="SMALL"
              variant="TEXT_ONLY"
              onClick={() => handleEdit(pos)}
              disabled={isSubmitting || isDeleting}
            >
              Edit
            </Button>

            <span className="icon-tertiary text-sm">|</span>

            <Button
              size="SMALL"
              variant="TEXT_ONLY"
              disabled={(addresses?.length ?? 0) < 2 || isSubmitting}
              onClick={() => handleDelete(address.id ?? 'no-address-id')}
              loading={isDeleting}
            >
              Delete
            </Button>
          </div>
        </div>

        <div className="grid grid-cols-2 md:grid-cols-4 gap-4">
          <div className="space-y-2 col-span-2 md:col-span-1">
            <p className="text-body-2 text-tertiary">Address:</p>

            <p className="text-secondary">{address?.fullAddress}</p>
          </div>

          <div className="space-y-2">
            <p className="text-body-2 text-tertiary">Time at Address:</p>

            <p className="text-secondary">
              {address?.durationYears} Yrs {address?.durationMonths} Mos
            </p>
          </div>

          <div className="space-y-2">
            <p className="text-body-2 text-tertiary">Housing Status:</p>

            <p className="text-secondary">
              {
                housingStatusOptions.find(
                  (o) => o.value === address?.housingStatus
                )?.label
              }
            </p>
          </div>

          <div className="space-y-2">
            <p className="text-body-2 text-tertiary">Monthly Payment:</p>

            <p className="text-secondary">
              ${address?.monthlyPayment?.toLocaleString()}/month
            </p>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="flex flex-col space-y-8 md:space-y-16 w-full items-center">
      {(!moreThanTwoYears || isEditing) && (
        <div className="flex flex-col w-full space-y-4 bg-secondary border-y border-secondary items-center py-6 px-6">
          <div className="flex flex-row justify-between w-full max-w-screen-md items-center">
            <h2>
              {timelinePosition === 0 &&
                t('Address Information and Housing Status')}
              {timelinePosition === 1 &&
                t('Previous Address Information and Housing Status')}
              {timelinePosition === 2 &&
                t('2nd Previous Address Information and Housing Status')}
              {timelinePosition === 3 &&
                t('3rd Previous Address Information and Housing Status')}
              {timelinePosition > 3 &&
                `${timelinePosition}th Previous Address Information and Housing Status`}
            </h2>
          </div>

          <form.Provider>
            <form
              className="grid grid-cols-2 md:grid-cols-8 gap-4 w-full max-w-screen-md md:items-end"
              onSubmit={(e) => {
                e.preventDefault();
                e.stopPropagation();
                void form.handleSubmit();
              }}
            >
              <form.Field name="street">
                {(field) => {
                  return (
                    <TextInput
                      fieldName={field.name}
                      value={field.state.value}
                      labelText="Street"
                      subtitleText="Street"
                      placeholder="Street"
                      required
                      disabled={isSubmitting || isDeleting}
                      error={field.state.meta.touchedErrors.at(0)}
                      onChange={(e) => field.handleChange(e.target.value)}
                      containerClassName="md:col-span-4"
                      dataTestId="hca-step2-street-input"
                    />
                  );
                }}
              </form.Field>

              <form.Field name="apartmentDetails">
                {(field) => {
                  return (
                    <TextInput
                      fieldName={field.name}
                      value={field.state.value}
                      labelText="Apartment Details"
                      subtitleText="Apartment Details"
                      placeholder="Suite, Apt #"
                      disabled={isSubmitting || isDeleting}
                      error={field.state.meta.touchedErrors.at(0)}
                      onChange={(e) => field.handleChange(e.target.value)}
                      containerClassName="md:col-span-4"
                      dataTestId="hca-step2-apartment-details-input"
                    />
                  );
                }}
              </form.Field>

              <form.Field name="zipCode">
                {(field) => {
                  return (
                    <TextInput
                      fieldName={field.name}
                      value={field.state.value}
                      labelText="Zip Code"
                      subtitleText="Zip Code"
                      placeholder="Zip Code"
                      required
                      disabled={isSubmitting || isDeleting}
                      error={field.state.meta.touchedErrors.at(0)}
                      onChange={(e) => field.handleChange(e.target.value)}
                      containerClassName="md:col-span-3"
                      dataTestId="hca-step2-zipcode-input"
                    />
                  );
                }}
              </form.Field>

              <form.Field name="city">
                {(field) => {
                  return (
                    <TextInput
                      fieldName={field.name}
                      value={field.state.value}
                      labelText="City"
                      subtitleText="City"
                      placeholder="City"
                      required
                      disabled={isSubmitting || isDeleting}
                      error={field.state.meta.touchedErrors.at(0)}
                      onChange={(e) => field.handleChange(e.target.value)}
                      containerClassName="md:col-span-2"
                      dataTestId="hca-step2-city-input"
                    />
                  );
                }}
              </form.Field>

              <form.Field name="state">
                {(field) => {
                  return (
                    <DropDown
                      fieldName={field.name}
                      value={field.state.value}
                      options={stateOptions}
                      labelText="State"
                      placeholder="State"
                      subtitleText="State"
                      required
                      disabled={isSubmitting || isDeleting}
                      error={field.state.meta.touchedErrors.at(0)}
                      onChange={(e) => field.handleChange(e.target.value)}
                      containerClassName="col-span-2  md:col-span-3"
                      dataTestId="hca-step2-state-input"
                    />
                  );
                }}
              </form.Field>

              <form.Field name="housingStatus">
                {(field) => {
                  return (
                    <DropDown
                      fieldName={field.name}
                      value={field.state.value}
                      options={housingStatusOptions}
                      labelText="Housing Status"
                      subtitleText="Housing Status"
                      placeholder="Housing Status"
                      required
                      disabled={isSubmitting || isDeleting}
                      error={field.state.meta.touchedErrors.at(0)}
                      onChange={(e) => field.handleChange(e.target.value)}
                      containerClassName="md:col-span-4"
                      dataTestId="hca-step2-housing-status-input"
                    />
                  );
                }}
              </form.Field>

              <form.Field name="monthlyPayment">
                {(field) => {
                  return (
                    <NumberInput
                      fieldName={field.name}
                      value={field.state.value}
                      subtitleText="Monthly Payment"
                      prefix="$"
                      suffix="/month"
                      placeholder="/month"
                      required
                      disabled={isSubmitting || isDeleting}
                      onChange={(e) => field.handleChange(e.target.value)}
                      containerClassName="md:col-span-4"
                      dataTestId="hca-step2-monthly-input"
                    />
                  );
                }}
              </form.Field>

              <form.Field name="durationYears">
                {(field) => {
                  return (
                    <NumberInput
                      fieldName={field.name}
                      value={field.state.value}
                      labelText="Duration in Years"
                      subtitleText="How many years have you been living at this address?"
                      placeholder="Duration (Yrs.)"
                      disabled={isSubmitting || isDeleting}
                      required
                      suffix=" Yrs."
                      onChange={(e) => field.handleChange(e.target.value)}
                      containerClassName="md:col-span-4"
                      dataTestId="hca-step2-duration-year-input"
                    />
                  );
                }}
              </form.Field>

              <form.Field name="durationMonths">
                {(field) => {
                  return (
                    <NumberInput
                      fieldName={field.name}
                      value={field.state.value}
                      labelText="Duration in Months"
                      subtitleText="How many months have you been living at this address?"
                      placeholder="Duration (Mos.)"
                      suffix=" Mos."
                      disabled={isSubmitting || isDeleting}
                      onChange={(e) => field.handleChange(e.target.value)}
                      containerClassName="md:col-span-4 h-full"
                      dataTestId="hca-step2-duration-months-input"
                    />
                  );
                }}
              </form.Field>

              <div className="flex flex-row justify-end col-span-2 md:col-span-8">
                <form.Subscribe
                  selector={(state) => [state.values]}
                  children={([values]) => {
                    const canContinue =
                      !!values.street &&
                      !!values.zipCode &&
                      !!values.city &&
                      !!values.state &&
                      !!values.housingStatus &&
                      !!values.monthlyPayment &&
                      values.durationYears !== '' &&
                      (parseInt(values.durationYears.toString()) > 0 ||
                        parseInt(values.durationMonths.toString()) > 0);

                    return (
                      <Button
                        disabled={!canContinue || isDeleting}
                        loading={isSubmitting}
                        type="submit"
                        dataTestId="hca-step2-save-button"
                      >
                        Save
                      </Button>
                    );
                  }}
                />
              </div>
            </form>
          </form.Provider>
        </div>
      )}

      <div className="flex w-full px-6 justify-center">
        <div className="flex flex-col space-y-6 md:space-y-12 w-full max-w-screen-md bg-primary">
          {[
            ...new Array(addresses?.length),
            addresses?.find((a) => !!a.housingStatus) && !moreThanTwoYears
              ? {}
              : 'SKIP'
          ].map((e, index) => {
            if (e !== 'SKIP') {
              return renderAddress(index);
            }
          })}
        </div>
      </div>

      <div className="flex w-full px-6 justify-center">
        <div className="flex flex-row justify-end w-full max-w-screen-md">
          <Button
            onClick={handleContinue}
            disabled={isSubmitting || !moreThanTwoYears || isDeleting}
            dataTestId="hca-step2-next-button"
          >
            Next
          </Button>
        </div>
      </div>
    </div>
  );
};

const HardCreditApplicationAddressInfoPage = () => {
  const navigate = useNavigate();
  const { transactionId, hardCreditApplicationId, dealershipSlug } = useParams(
    '/dashboard/:dealershipSlug/:transactionId/hardCredit/:hardCreditApplicationId/addressInfo'
  );
  const { data: meData } = useQuery(meQuery());
  const { data: customer, isFetched } = useQuery(
    customerQuery(transactionId, meData?.me?.user?.id, dealershipSlug)
  );
  const [isDeleting, setIsDeleting] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const handleCancel = () => {
    navigate('/dashboard/:dealershipSlug/:transactionId', {
      params: { transactionId, dealershipSlug }
    });
  };
  const addresses = customer?.customer?.residentialAddresses;

  return (
    <div className="flex flex-col h-dvh" data-test-id={'hca-step-2-body'}>
      <Header
        title="Hard Credit Application"
        leftElement={
          <button
            className=" text-primary-brand"
            onClick={handleCancel}
            disabled={isSubmitting || isDeleting}
          >
            Cancel
          </button>
        }
        totalSteps={5}
        currentStep={2}
      />

      <div className="flex w-full px-6 justify-center z-30">
        <div className="flex w-full max-w-screen-md self-center pt-4">
          <Link
            to="/dashboard/:dealershipSlug/:transactionId/hardCredit/:hardCreditApplicationId/basicInfo"
            params={{ transactionId, hardCreditApplicationId, dealershipSlug }}
            className="flex w-6"
          >
            <div className="relative">
              <ChevronRightIcon className="w-6 -scale-x-100 icon-tertiary" />
            </div>
          </Link>
        </div>
      </div>

      <div className="flex flex-col py-10 space-y-6 w-full items-center overflow-y-scroll h-full">
        {isFetched ? (
          <Fragment>
            <div className="flex w-full px-6 justify-center">
              <div className="flex flex-col space-y-4 w-full max-w-screen-md bg-primary">
                <h2>Credit Application</h2>

                <p>Including the last two years of your housing is required.</p>
              </div>
            </div>

            <HardCreditApplicationAddressInfoForm
              addresses={addresses?.edges?.map((e) => e.node ?? {})}
              isDeleting={isDeleting}
              setIsDeleting={setIsDeleting}
              isSubmitting={isSubmitting}
              setIsSubmitting={setIsSubmitting}
            />
          </Fragment>
        ) : (
          <div
            className="flex w-full aspect-square justify-center items-center relative"
            data-test-id={'hca-step2-spinner'}
          >
            <Spinner className="w-10 aspect-square" />
          </div>
        )}
      </div>
    </div>
  );
};

export default HardCreditApplicationAddressInfoPage;
