import { FormSubmissionDataSchema } from '@thedealersconcierge/lib/codecs/schema/formSubmissionData';
import { FormSubmissionType } from '~/__generated__/backend/zeus';
import { AddressType } from '~/querySelectors/address';
import { DealershipType } from '~/querySelectors/dealership';
import { EmploymentType } from '~/querySelectors/employment';
import { HardCreditApplicationType } from '~/querySelectors/hardCreditApplication';
import { UserType } from '~/querySelectors/userSelector';
import createFormSubmissionAction from '../createFormSubmissionAction';
import { submitHardCreditApplicationAction } from './submitHardCreditApplicationAction';
import uploadHardCreditApplicationModificationAction from './uploadHardCreditApplicationModificationAction';

const readFileAsDataURL = (blob: Blob) => {
  return new Promise<string | undefined>((resolve, reject) => {
    const reader = new FileReader();
    reader.onloadend = () => resolve(reader.result?.toString());
    reader.onerror = reject;
    reader.readAsDataURL(blob);
  });
};

const submitHardCreditApplicationModificationAction = async (
  transactionId: string,
  user: UserType,
  dealership: DealershipType,
  hardCreditApplication: HardCreditApplicationType,
  addresses: AddressType[],
  employments: EmploymentType[],
  ipAddress: string
) => {
  // Generate and upload modification notice PDF
  const modificationFile = await uploadHardCreditApplicationModificationAction(
    dealership,
    hardCreditApplication,
    ipAddress
  );

  const formSubmissionData: FormSubmissionDataSchema = {
    submissionType: 'HARD_CREDIT_APPLICATION_MODIFICATION',
    submissionData: {}
  };

  // Create form submission for modification notice
  await createFormSubmissionAction(
    transactionId,
    FormSubmissionType.HARD_CREDIT_APPLICATION_MODIFICATION,
    modificationFile?.fileId ?? 'no-file-id',
    JSON.stringify(formSubmissionData)
  );

  // We have to download the initial signature
  const signatureResp = await fetch(
    hardCreditApplication.signature?.url ?? 'no-signature-url'
  );
  const signatureBlob = await signatureResp.blob();
  const signature = (await readFileAsDataURL(signatureBlob)) ?? 'no-signature';

  return await submitHardCreditApplicationAction(
    transactionId,
    user,
    dealership,
    hardCreditApplication,
    addresses,
    employments,
    ipAddress,
    signature
  );
};

export default submitHardCreditApplicationModificationAction;
