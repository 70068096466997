import { useForm } from '@tanstack/react-form';
import { useQuery } from '@tanstack/react-query';
import { FC, Fragment, useState } from 'react';
import { submitHardCreditApplicationAction } from '~/actions/formSubmissions/hardCreditApplication/submitHardCreditApplicationAction';
import Button from '~/components/Button';
import Header from '~/components/Header';
import Signature from '~/components/Signature';
import Spinner from '~/components/Spinner';
import ChevronRightIcon from '~/components/icons/ChevronRightIcon';
import TextInput from '~/components/inputs/TextInput';
import { gqlMutationClient } from '~/lib/backend';
import { base64ToImageBlob } from '~/lib/files';
import customerQuery from '~/queries/customerQuery';
import dealershipQuery, {
  DealershipQueryType
} from '~/queries/dealershipQuery';
import {
  mkHardCreditQuery,
  resetHardCreditQuery
} from '~/queries/hardCreditQuery';
import meQuery, { MeQueryType, resetMeQuery } from '~/queries/meQuery';
import { AddressType } from '~/querySelectors/address';
import { EmploymentType } from '~/querySelectors/employment';
import { HardCreditApplicationType } from '~/querySelectors/hardCreditApplication';
import { Link, useNavigate, useParams } from '~/router';

const HardCreditApplicationSubmitApplicationForm: FC<{
  dealership: DealershipQueryType;
  dealershipId: string;
  hardCreditApplication?: HardCreditApplicationType;
  employments: EmploymentType[];
  addresses: AddressType[];
  isSubmitting: boolean;
  meData: MeQueryType;
  setIsSubmitting: React.Dispatch<React.SetStateAction<boolean>>;
}> = ({
  dealership,
  meData,
  dealershipId,
  hardCreditApplication,
  employments,
  addresses,
  isSubmitting,
  setIsSubmitting
}) => {
  const navigate = useNavigate();
  const [creditSignature, setCreditSgignature] = useState<string | null>(null);
  const { transactionId, dealershipSlug } = useParams(
    '/dashboard/:dealershipSlug/:transactionId/hardCredit/:hardCreditApplicationId/submitApplication'
  );

  // We have ended here through
  const handleSubmitSignature = async (creditSignature: string) => {
    setCreditSgignature(creditSignature);

    const signatureUpload = await gqlMutationClient()({
      makeUpload: [
        {
          fileExt: 'png',
          mimeType: 'image/png'
        },
        {
          __typename: true,
          '...on GraphQLError': { message: true },
          '...on MutationMakeUploadSuccess': {
            data: {
              fileId: true,
              uploadUrl: true
            }
          }
        }
      ]
    });

    const optimisticResp =
      signatureUpload.makeUpload?.__typename !== 'GraphQLError'
        ? signatureUpload.makeUpload?.data
        : undefined;

    const imageBase64 = creditSignature.split(',')[1];
    const imageBlob = base64ToImageBlob(imageBase64 ?? '', 'image/png');
    const imageFile = new File(
      [imageBlob],
      'hard_credit_application_signature',
      {
        type: 'image/png'
      }
    );

    if (optimisticResp?.uploadUrl) {
      await fetch(optimisticResp.uploadUrl, {
        method: 'PUT',
        headers: {
          'Content-Type': 'image/png',
          'Content-Lenght': imageFile.size.toString()
        },
        body: imageFile
      });
    }

    const resp = await gqlMutationClient()({
      updateHardCreditApplication: [
        {
          id: hardCreditApplication?.id ?? '',
          hardCreditApplication: {
            signatureId: optimisticResp?.fileId
          }
        },
        {
          __typename: true,
          '...on GraphQLError': {
            message: true
          },
          '...on MutationUpdateHardCreditApplicationSuccess': {
            data: {
              status: true
            }
          }
        }
      ]
    });

    if (
      !resp.updateHardCreditApplication ||
      resp.updateHardCreditApplication.__typename === 'GraphQLError'
    ) {
      console.error(
        'We could not save the signature. Please try again or contact support.'
      );
    }
  };
  const form = useForm({
    defaultValues: {
      name: ''
    },
    onSubmit: async (values) => {
      if (
        !creditSignature ||
        !hardCreditApplication ||
        !meData.me?.user ||
        !dealership.dealership ||
        !meData.me.ipAddress
      ) {
        // TODO: Handle more elegantly
        console.error('Missing data');

        return;
      }

      setIsSubmitting(true);

      const formSubmission = await submitHardCreditApplicationAction(
        transactionId,
        meData.me.user,
        dealership.dealership,
        hardCreditApplication,
        addresses,
        employments,
        meData.me.ipAddress,
        creditSignature,
        values.name
      );

      // TODO: Refactor into action
      const respUpdateHardCreditApplication = await gqlMutationClient()({
        updateHardCreditApplication: [
          {
            id: hardCreditApplication.id ?? '',
            hardCreditApplication: {
              formSubmissionId: formSubmission?.id
            }
          },
          {
            __typename: true,
            '...on GraphQLError': {
              message: true
            },
            '...on MutationUpdateHardCreditApplicationSuccess': {
              data: { status: true }
            }
          }
        ]
      });

      if (
        respUpdateHardCreditApplication.updateHardCreditApplication
          ?.__typename === 'GraphQLError'
      ) {
        console.error(
          respUpdateHardCreditApplication.updateHardCreditApplication.message
        );

        window.alert(
          'Could not submit hard credit application. Please try again or contact support.'
        );
      } else {
        resetMeQuery();
        resetHardCreditQuery(transactionId);
        navigate('/dashboard/:dealershipSlug/:transactionId', {
          params: { transactionId, dealershipSlug }
        });
      }

      setIsSubmitting(false);
    }
  });

  return (
    <div className="flex flex-col space-y-10 w-full max-w-screen-md px-6">
      <p className="text-secondary">
        <b>Our Commitment to Privacy</b>
        <br />
        All information stored in our database is secure and is strictly
        confidential. Your personal and credit information will only be used to
        fulfill your request and in accordance with our Privacy Policy.
        <br />
        <br />
        <b>Acknowledgment and Consent</b>
        <br />
        By clicking the "Submit" button and providing an electronic signature
        below, you are confirming you have read and understood the{' '}
        <a className=" text-primary-brand cursor-pointer">
          Application Disclosure
        </a>
        , and you are providing written instructions to this Dealership to
        obtain information from Experian and/or TransUnion and/or Equifax to
        apply for credit.
      </p>

      <form.Provider>
        <form
          className="flex flex-col"
          onSubmit={(e) => {
            e.preventDefault();
            e.stopPropagation();
            void form.handleSubmit();
          }}
        >
          <div className="flex flex-col space-y-2">
            <p className="text-secondary">
              Please enter your full name before signing
            </p>

            <div>
              <form.Field name="name">
                {(field) => {
                  return (
                    <TextInput
                      fieldName={field.name}
                      value={field.state.value}
                      labelText="Full Name"
                      subtitleText="Full Name"
                      placeholder="Full Name"
                      required
                      disabled={isSubmitting}
                      error={field.state.meta.touchedErrors.at(0)}
                      onChange={(e) => field.handleChange(e.target.value)}
                      dataTestId="hca-step5-fullname-input"
                    />
                  );
                }}
              </form.Field>
            </div>
          </div>
        </form>
      </form.Provider>

      <div className="flex flex-col space-y-2">
        <p className="text-secondary">Signature</p>

        <Signature
          onSignatureSubmit={handleSubmitSignature}
          existingSignature={creditSignature}
          dataTestIdOpenModal="hca-step5-signature-open-modal"
          dataTestIdCanvas="hca-step5-signature-canvas"
          dataTestIdSubmit="hca-step5-signature-submit"
        />
      </div>

      <div className="flex flex-row justify-end">
        <form.Subscribe
          selector={(state) => [state.values]}
          children={([values]) => {
            const canContinue =
              !!creditSignature && !!values.name.trim().length;

            return (
              <Button
                onClick={() => form.handleSubmit()}
                disabled={!canContinue}
                loading={isSubmitting}
                dataTestId="hca-step5-submit-button"
              >
                Submit
              </Button>
            );
          }}
        />
      </div>
    </div>
  );
};

const HardCreditApplicationSubmitApplicationPage = () => {
  const navigate = useNavigate();
  const { transactionId, hardCreditApplicationId, dealershipSlug } = useParams(
    '/dashboard/:dealershipSlug/:transactionId/hardCredit/:hardCreditApplicationId/submitApplication'
  );
  const { data, isFetched } = useQuery(mkHardCreditQuery(transactionId));
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { data: meData } = useQuery(meQuery());

  const handleCancel = () => {
    navigate('/dashboard/:dealershipSlug/:transactionId', {
      params: { transactionId, dealershipSlug }
    });
  };

  const { data: customer } = useQuery(
    customerQuery(transactionId, meData?.me?.user?.id, dealershipSlug)
  );

  const hardCreditApplication =
    customer?.customer?.hardCreditApplications?.edges?.at(0)?.node;

  const addresses = customer?.customer?.residentialAddresses?.edges?.map(
    (e) => e.node
  );
  const employments = customer?.customer?.employments?.edges?.map(
    (e) => e.node
  );
  const dealershipId = data?.transaction?.dealership?.id;

  const { data: dealershipData } = useQuery(
    dealershipQuery({ id: dealershipId })
  );

  return (
    <div className="flex flex-col h-dvh">
      <Header
        title="Hard Credit Application"
        leftElement={
          <button
            className=" text-primary-brand"
            onClick={handleCancel}
            disabled={isSubmitting}
          >
            Cancel
          </button>
        }
        totalSteps={5}
        currentStep={5}
      />

      <div
        className="flex w-full max-w-screen-md z-30 self-center pt-4 px-6"
        data-test-id={'hca-step5-body'}
      >
        <Link
          to="/dashboard/:dealershipSlug/:transactionId/hardCredit/:hardCreditApplicationId/personalReference"
          params={{ transactionId, hardCreditApplicationId, dealershipSlug }}
          className="flex w-6"
        >
          <div className="relative">
            <ChevronRightIcon className="w-6 -scale-x-100 icon-tertiary" />
          </div>
        </Link>
      </div>

      <div className="flex flex-col py-10 space-y-6 w-full items-center overflow-y-scroll h-full">
        {isFetched &&
        addresses &&
        dealershipId &&
        employments &&
        meData &&
        dealershipData ? (
          <Fragment>
            <div className="flex flex-col space-y-4 w-full max-w-screen-md bg-primary px-6">
              <h2>Submit Credit Application</h2>

              <p>Please read the information below before agreeing.</p>
            </div>

            <HardCreditApplicationSubmitApplicationForm
              dealership={dealershipData}
              meData={meData}
              dealershipId={dealershipId}
              hardCreditApplication={hardCreditApplication}
              isSubmitting={isSubmitting}
              setIsSubmitting={setIsSubmitting}
              addresses={addresses.map((a) => a ?? {})}
              employments={employments.map((e) => e ?? {})}
            />
          </Fragment>
        ) : (
          <div className="flex w-full aspect-square justify-center items-center relative">
            <Spinner className="w-10 aspect-square" />
          </div>
        )}
      </div>
    </div>
  );
};

export default HardCreditApplicationSubmitApplicationPage;
