import { FormSubmissionType } from '~/__generated__/backend/zeus';
import { AddressType } from '~/querySelectors/address';
import { DealershipType } from '~/querySelectors/dealership';
import { EmploymentType } from '~/querySelectors/employment';
import { HardCreditApplicationType } from '~/querySelectors/hardCreditApplication';
import { UserType } from '~/querySelectors/userSelector';
import createFormSubmissionAction from '../createFormSubmissionAction';
import { uploadHardCreditApplicationPdfAction } from './uploadHardCreditApplicationAction';

export const submitHardCreditApplicationAction = async (
  transactionId: string,
  user: UserType,
  dealership: DealershipType,
  hardCreditApplication: HardCreditApplicationType,
  addresses: AddressType[],
  employments: EmploymentType[],
  ipAddress: string,
  signature: string,
  signatureName?: string
) => {
  // Generate and upload hard credit application PDF
  const [uploadResponse, formSubmissionData] =
    await uploadHardCreditApplicationPdfAction(
      user,
      dealership,
      hardCreditApplication,
      addresses,
      employments,
      signature,
      ipAddress,
      signatureName
    );

  return await createFormSubmissionAction(
    transactionId,
    FormSubmissionType.HARD_CREDIT_APPLICATION,
    uploadResponse?.fileId ?? 'no-file-id',
    JSON.stringify(formSubmissionData)
  );
};
