import { PrequalFormSchema } from '@thedealersconcierge/lib/codecs/schema/prequalApplication';
import { generatePdf } from '@thedealersconcierge/lib/pdf-gen';
import { format } from 'date-fns';
import stateOptions from '~/config/formSelectionOptions/stateOptions';
import { gqlMutationClient } from '~/lib/backend';
import { prequalForm } from '~/lib/form/prequal-form';
import uploadFileAction from '../uploadFileAction';

const submitPreQualApplicationAction = async (
  values: {
    firstName: string;
    middleName: string;
    lastName: string;
    birthdate: Date;
    socialSecurityNumber: string;
    addressStreet: string;
    addressApartmentDetails: string;
    addressZipCode: string;
    addressCity: string;
    addressState: string;
    acknowledgesInformationUnderstanding: boolean;
    understandsCreditScoreAffection: boolean;
    acknowledgesPrivacyNotice: boolean;
    providedInstructions: boolean;
  },
  dealershipName: string,
  ipAddress: string,
  signature: string,
  transactionId: string
) => {
  const formData: PrequalFormSchema = {
    state: values.addressState,
    // TODO: Acquire this
    suffix: '',
    middleName: values.middleName,
    birthdate: values.birthdate,
    dateTime: format(new Date(), 'MM/dd/yyyy hh:mm a'),
    name: `${values.firstName} ${values.lastName}`.trim(),
    ipAddress,
    deviceId: window.navigator.userAgent,
    street: values.addressStreet,
    apartmentDetails: values.addressApartmentDetails,
    city: values.addressCity,
    zip: values.addressZipCode,
    signature,
    socialSecurityNumber: values.socialSecurityNumber,
    firstName: values.firstName,
    lastName: values.lastName
  };

  const pdf = await generatePdf(
    prequalForm,
    {
      ...formData,
      dealershipName,
      state:
        stateOptions.find((o) => o.value === formData.state)?.label ??
        formData.state,
      birthdate: format(new Date(values.birthdate), 'MM/dd/yyyy'),
      middleName: formData.middleName ?? ''
    },
    false
  );

  const pdfBlob = new Blob([pdf]);

  const prequal = await uploadFileAction('pdf', 'application/pdf', pdfBlob);

  const appl = await gqlMutationClient()({
    // Update the customer's social security number
    updateCustomer: [
      {
        transactionId,
        customer: {
          socialSecurityNumber: formData.socialSecurityNumber
        }
      },
      {
        __typename: true,
        '...on GraphQLError': { message: true },
        '...on MutationUpdateCustomerSuccess': { data: { status: true } }
      }
    ],

    // Submit the prequal application
    submitPrequalApplicationOnTransaction: [
      {
        transactionId,
        formData,
        signedUploadedDocumentId: prequal.fileId ?? 'should-never-happen'
      },
      {
        __typename: true,
        '...on GraphQLError': {
          message: true
        },
        '...on MutationSubmitPrequalApplicationOnTransactionSuccess': {
          data: {
            status: true
          }
        }
      }
    ]
  });

  if (
    !appl.submitPrequalApplicationOnTransaction ||
    appl.submitPrequalApplicationOnTransaction.__typename === 'GraphQLError'
  ) {
    throw new Error(
      appl.submitPrequalApplicationOnTransaction?.message ?? 'Unexpected error'
    );
  }
};

export default submitPreQualApplicationAction;
